﻿@use "../config" as *;
@use "../mixins" as *;

.m-arrow-link
{
	@include critical
	{
		position: relative;
		display: inline-block;
		padding-left: 25px;
		font-weight: 500;
		text-decoration: none;
		color: $colorBlack;

		.cms &
		{
			text-decoration: none;
		}

		&:before
		{
			content: '';
			display: block;
			width: 14px;
			height: 10px;
			background-image: url(../images/arrow-alt.png);
			background-size: 100% 100%;
			background-repeat: no-repeat;
			background-position: 0 0;
			position: absolute;
			left: 0;
			top: 50%;
			@include transform(translateY(-50%));
		}
	}

	&--pseudo
	{
		@include critical
		{
			padding: 10px 0 10px 25px;
			margin-top: -10px;
			position: relative;
			color: $colorBody;
		}

		&:before,
		&:after
		{
			@include deferred
			{
				content: '';
				display: block;
				position: absolute;
				border: 2px solid $colorBody;
				left: 0px;
				top: 50%;
			}
		}

		&:before
		{
			@include deferred
			{
				width: 6px;
				height: 6px;
				border-bottom-width: 0;
				border-left-width: 0;
				@include transform(translate(5px, -50%) rotate(45deg));
			}
		}

		&:after
		{
			@include deferred
			{
				width: 13px;
				height: 1px;
				border-bottom-width: 0;
				border-right-width: 0;
				border-left-width: 0;
				@include transform(translateY(-1px));
			}
		}

		&:hover
		{
			@include deferred
			{
				color: $colorGrey;
			}

			&:before,
			&:after
			{
				@include deferred
				{
					border-color: $colorGrey;
				}
			}
		}
	}
}
