﻿@use "../config" as *;
@use "../mixins" as *;
@use "sass:math";

.m-popup
{
	@include deferred
	{
		background: $colorWhite;
		@include box-shadow(0 0 20px 0 rgba($colorBlack, .25));
		margin: 40px auto;
		padding: 45px 25px;
		position: relative;
		text-align: left;
		max-width: $maxContentWidth;
		@include mq-min($bpTablet)
		{
			padding: 60px 40px;
		}
	}
	&--confirm
	{
		@include deferred
		{
			max-width: math.div($maxPageWidth, 2);
		}
	}
	&--wide
	{
		@include deferred
		{
			max-width: $maxPageWidth;
		}
	}
}

.mfp-wrap
{
	@include deferred {
		overflow-y: scroll !important; // very important
		top: 0 !important; // very important
		height: 100% !important; // very important
	}
	.mfp-close
	{
		@include deferred
		{
			@include box-sizing();
			color: $colorBlack;
			display: block;
			@include font-size(14px);
			font-weight: 300;
			height: auto;
			min-height: 30px;
			@include line-height(14px);
			margin: 0;
			opacity: 1;
			padding: 7px 32px 0 0;
			position: absolute;
			right: 20px;
			@include text-hidden();
			top: 30px;
			width: 35px;
			z-index: 1;
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
			cursor: pointer;
			@include mq-min($bpTablet)
			{
				right: 30px;
				@include remove-text-hidden();
				width: auto;
			}
		}
		&:before, &:after
		{
			@include deferred
			{
				border-left: 2px solid $colorBlack;
				content: "";
				display: block;
				height: 24px;
				margin: 0;
				padding: 0;
				position: absolute;
				right: 15px;
				top: 3px;
				width: 0;
				z-index: 1;
			}
		}
		&:before
		{
			@include deferred
			{
				@include rotate(-45deg);
			}
		}
		&:after
		{
			@include deferred
			{
				@include rotate(45deg);
			}
		}
		&:hover
		{
			@include deferred
			{
				opacity: .8;
			}
		}

		@include deferred {
			@include focus-hide() {
				outline: none;
			}

			.js-focus-visible &:focus.focus-visible {
				outline: 4px solid $colorFocus;
			}
		}
	}
	.mfp-iframe-holder
	{
		.mfp-iframe-scaler
		{
			@include deferred
			{
				background: $colorBlack;
			}
		}
		.mfp-close
		{
			@include deferred
			{
				right: -5px;
				top: -35px;
			}
		}
	}
	&.mfp-flush
	{
		.mfp-container
		{
			@include deferred
			{
				padding: 0;
			}
		}		
	}
	&.mfp-close-alt
	{
		.mfp-close
		{
			&:before, &:after
			{
				@include deferred
				{
					border-left-color: $colorWhite;
				}
			}

			@include deferred {

				.js-focus-visible &:focus.focus-visible {
					outline: 4px solid $colorFocusOnDark;
				}
			}
		}
	}
	&.mfp-close-no-text
	{
		.mfp-close
		{
			@include deferred
			{
				@include text-hidden();
				width: 32px;
			}
		}
	}
	&.mfp-zoom-in
	{
		@include deferred
		{
			.mfp-content
			{
				opacity: 0;
				@include transform(scale(.8));
				@include transition(opacity .2s, transform .2s);
				will-change: opacity, transform;
			}
			&.mfp-ready
			{
				.mfp-content
				{
					opacity: 1;
					@include transform(scale(1));
				}
			}
			&.mfp-removing
			{
				.mfp-content
				{
					opacity: 0;
					@include transform(scale(.8));
				}
			}
		}
	}
	&.mfp-fade-in
	{
		.mfp-content
		{
			opacity: 0;
			@include transition(opacity .2s);
			will-change: opacity;
		}
		&.mfp-ready
		{
			.mfp-content
			{
				opacity: 1;
			}
		}
		&.mfp-removing
		{
			.mfp-content
			{
				opacity: 0;
			}
		}
	}
	&.mfp-search-overlay
	{
		@include deferred
		{
			background: $colorBlack;
		}
		.mfp-close
		{
			@include deferred
			{
				right: 5%;
				top: 20px;
				position: fixed;
				@include mq-min($bpDesktop)
				{
					left: 81%;
					top: 70px;
				}
			}
		}
		.mfp-content
		{
			@include deferred
			{
				vertical-align: top;
				height: 100%;
			}
		}
	}
	&.mfp-filters-overlay,
	&.mfp-language-selector
	{
		@include deferred
		{
			background: $colorWhite;
		}
		.mfp-close
		{
			@include deferred
			{
				z-index: 101;
				font-size: 0;
				line-height: 0;
				text-align: left;
				text-indent: -9000px;
				display: inline-block;
				width: 40px;
				height: 40px;
				padding: 0;
				top: 25px;
				right: auto;
				position: relative;
				vertical-align: top;

				@include mq-min($bpDesktop)
				{
					top: 40px;
				}

				&:before,
				&:after
				{
					top: 50%;
					left: 50%;
					right: auto;
					margin-top: -12px;

					@include mq-min($bpDesktop)
					{
						height: 52px;
						margin-top: -26px;
					}
				}
			}
		}
		.mfp-content
		{
			@include deferred
			{
				vertical-align: top;
				height: 100%;
			}
		}
	}
	&.mfp-no-close-text
	{
		.mfp-close
		{
			@include deferred
			{
				right: 13px;
				top: 13px;
				z-index: 101;
				font-size: 0;
				line-height: 0;
				text-align: left;
				text-indent: -9000px;
				display: block;
				width: 40px;
				height: 40px;
				padding: 0;
				top: 9px;
				right: 4px;
				@include mq-min($bpTablet)
				{
					right: 8px;
					top: 12px;
				}
				@include mq-min($bpDesktop)
				{
					right: 18px;
					top: 24px;
				}
				&:before,
				&:after
				{
					top: 50%;
					left: 50%;
					right: auto;
					margin-top: -12px;
					@include mq-min($bpDesktop)
					{
						height: 28px;
						margin-top: -14px;
					}
				}
			}
		}
	}
}

.mfp-bg
{
	opacity: 0;
	@include transition(opacity .2s);
	&.mfp-ready
	{
		opacity: .6;
	}
	&.mfp-removing
	{
		opacity: 0;
	}
}

.mfp-preloader
{
	@include deferred
	{
		@include bg('/images/loader.gif', no-repeat, 50% 50%);
		background-color: $colorWhite;
		@include background-size(60%);
		@include border-radius(100%);
		@include box-shadow(0 0 20px 0 rgba($colorBlack, .25));
		height: 50px;
		left: 50%;
		margin: -25px 0 0 -25px;
		padding: 0;
		position: fixed;
		@include text-hidden();
		top: 50%;
		width: 50px;
		z-index: 1044;
	}
}

.mfp-video .mfp-content {
	@include deferred {
		position: relative;
	}
}

.mfp-footer-link {
	@include deferred {
		position: absolute;
		bottom: -5rem;
		left: 0;
	}
}