﻿@use "../config" as *;
@use "../mixins" as *;

.m-definition-list
{
	@include deferred
	{
		margin: 0;
		padding: 0;
	}
}

.m-definition-list__dt
{
	@include deferred
	{
		margin: 0;
		padding: 0;
		display: inline;
		font-weight: bold;
	}
}

.m-definition-list__dd
{
	@include deferred
	{
		margin: 0;
		padding: 0;
		display: inline;

		&:not(:last-child):after {
			content: '';
			display: block;
			height: 15px;
			width: 100%;
		}
	}
}