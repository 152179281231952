﻿@use "../config" as *;
@use "../mixins" as *;

.m-page-title {
	@include critical {
		padding: 27px 0 12px 0;

		@include mq-min($bpTablet) {
			padding: 20px 0 16px 0;
		}

		@include mq-min($bpDesktop) {
			padding: 36px 0 19px 0;
		}

		&--width {
			&-two-thirds-width {
				@include critical {
					@include mq-min($bpTablet) {
						.m-page-title__inner__inner {
							max-width: 66.6666%;
						}
					}
				}
			}

			&-half-width {
				@include critical {
					@include mq-min($bpTablet) {
						.m-page-title__inner__inner {
							max-width: 50%;
						}
					}
				}
			}

			&-one-third-width {
				@include critical {
					@include mq-min($bpTablet) {
						.m-page-title__inner__inner {
							max-width: 50%;
						}
					}

					@include mq-min($bpDesktop) {
						.m-page-title__inner__inner {
							max-width: 33.3333%;
						}
					}
				}
			}
		}

		&--position {
			&-top {
				&-middle {
					@include critical {
						.m-page-title__inner {
							text-align: center;
						}
					}
				}

				&-right {
					@include critical {
						.m-page-title__inner {
							text-align: right;
						}
					}
				}
			}

			&-middle {
				&-middle {
					@include critical {
						.m-page-title__inner {
							text-align: center;
						}
					}
				}

				&-right {
					@include critical {
						.m-page-title__inner {
							text-align: right;
						}
					}
				}
			}

			&-bottom {
				&-middle {
					@include critical {
						.m-page-title__inner {
							text-align: center;
						}
					}
				}

				&-right {
					@include critical {
						.m-page-title__inner {
							text-align: right;
						}
					}
				}
			}
		}
	}

	&__inner {
		@include critical {
			width: 90%;
			max-width: $maxPageWidth;
			margin: 0 auto;
			text-align: left;
		}
	}

	&__inner__inner {
		@include critical {
			display: inline-block;
			width: 100%;
		}
	}

	&__title {
		@include critical {
			text-align: left;
			@include font-size(40px);
			@include line-height(48px);
			padding-bottom: 13px;

			@include mq-min($bpTablet) {
				@include font-size(48px);
				@include line-height(56px);
			}

			@include mq-min($bpDesktop) {
				@include font-size(64px);
				@include line-height(72px);
				padding-bottom: 17px;
			}

			&.js-scroll-in {
				.js:not(.prefers-reduce-motion) & {
					@include transform(translateY(30px));
					opacity: 0;

					&.scroll-in {
						@include transition(transform .3s linear .15s, opacity .3s linear .15s);
						@include transform(translateY(0));
						opacity: 1;
					}
				}
			}
		}
	}

	&__summary {
		@include critical {
			text-align: left;

			@include mq-min($bpDesktop) {
				@include font-size(20px);
				@include line-height(28px);
			}

			&.js-scroll-in {
				.js:not(.prefers-reduce-motion) & {
					@include transform(translateY(30px));
					opacity: 0;

					&.scroll-in {
						@include transition(transform .3s linear .15s, opacity .3s linear .15s);
						@include transform(translateY(0));
						opacity: 1;
					}
				}
			}
		}
	}

	&__cta {

		&.js-scroll-in {
			.js:not(.prefers-reduce-motion) & {
				@include transform(translateY(30px));
				opacity: 0;

				&.scroll-in {
					@include transition(transform .3s linear .15s, opacity .3s linear .15s);
					@include transform(translateY(0));
					opacity: 1;
				}
			}
		}
	}

	&__action {

		@include critical {
			padding: 15px 0 30px 0;

			&.js-scroll-in {
				.js:not(.prefers-reduce-motion) & {
					@include transform(translateY(30px));
					opacity: 0;

					&.scroll-in {
						@include transition(transform .3s linear .15s, opacity .3s linear .15s);
						@include transform(translateY(0));
						opacity: 1;
					}
				}
			}
		}
	}
}
