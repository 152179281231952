﻿@use "../config" as *;
@use "../mixins" as *;

.m-search-block {
	@include critical {
		padding-top: 23px;
		padding-bottom: 23px;

		@include mq-min($bpTablet) {
			padding-top: 32px;
			padding-bottom: 32px;
		}

		@include mq-min($bpDesktop) {
			padding-top: 40px;
			padding-bottom: 40px;
		}
	}

	&__row {
		@include critical {
			padding-bottom: 0;
			max-width: 725px;
		}
	}

	&__row__inner {
		@include critical {
			position: relative;
		}
	}

	&__label {
		@include critical {
			padding-bottom: 15px;

			.js & {
				display: inline-block;
				position: absolute;
				top: 50%;
				left: 9px;
				will-change: transform, top;
				transition: transform 0.25s ease, top 0.25s ease;
				transform: translate(0, -50%);
				background: #fff;
				padding: 0 9px;
			}
		}
	}

	&__label--move {
		@include critical {
			.js & {
				transform: translate(0, -11px);
				top: 0;

				@include mq-min($bpDesktop) {
					transform: translate(0, -14px);
				}
			}
		}
	}

	&__input {
		@include critical {
			padding-right: 68px;
			max-width: 100%;

			@include mq-min($bpDesktop) {
				padding-right: 78px;
			}
		}
	}

	&__button {
		@include critical {
			position: absolute;
			top: 1px;
			right: 1px;
			bottom: 1px;
			width: 52px;
			@include background-fill();
			display: block;
			cursor: pointer;

			svg {
				fill: $colorGreyDark;
				width: 27px;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}

			&:hover {

				svg {
					fill: $colorBlack;
				}
			}

			@include mq-min($bpDesktop) {
				width: 62px;
			}
		}

		@include deferred {

			@include focus-hide() {
				outline: none;
			}

			.js-focus-visible &:focus.focus-visible {
				outline: 4px solid $colorFocus;
			}
		}
	}

	&__button__inner {
		@include critical {
			display: block;
			width: 100%;
			height: 100%;
			position: relative;
		}
	}
}