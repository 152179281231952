﻿@use "../config" as *;
@use "../mixins" as *;

.m-language-selector
{
	@include deferred
	{
		background: $colorWhite;
		margin: 0;
		position: relative;
		width: 100%;
		min-height: 100%;
	}
	&__title
	{
		@include deferred
		{
			@include font-size(22px);
			height: 95px;
			padding: 0;
			position: fixed;
			left: 0;
			top: 0;
			width: 100%;
			background: $colorWhite;
			z-index: 10;
			border-bottom: 1px solid $colorOffWhite;

			@include mq-min($bpTablet)
			{
				@include font-size(24px);
			}

			@include mq-min($bpDesktop)
			{
				@include font-size(50px);
				height: 125px;
			}

			h2
			{
				padding: 0;
				position: relative;
				top: 50%;
				@include transform(translateY(-50%));
				padding-right: 100px;
			}
		}
	}
	&__title__inner
	{
		@include deferred
		{
			height: 100%;
		}
	}
	&__inner
	{
		@include deferred
		{
			padding-top: 95px + 5px;
			@include mq-min($bpTablet)
			{
				padding-top: 95px;
			}
			@include mq-min($bpDesktop)
			{
				padding-top: 125px + 5px;
			}
		}
	}
	&__menu
	{
		@include deferred
		{
			font-size: 0;
			line-height: 0;
		}		
		&__item
		{
			@include deferred
			{
				display: inline-block;
				padding: 15px 0;
				vertical-align: top;
				width: 100%;
				@include mq-min($bpTablet)
				{
					padding: 20px 0;
					width: 50%;
				}
				@include mq-min($bpDesktop)
				{
					padding: 25px 0;
					width: 25%;
				}
			}			
		}
		&__link
		{
			&:hover
			{
				.m-language-selector__menu__text
				{
					@include deferred
					{
						text-decoration: underline;
					}
				}
			}

			.js-focus-visible &:focus.focus-visible .m-language-selector__menu__text {
				outline: 4px solid $colorFocus;
			}
		}
		&__flag, &__text
		{
			@include deferred
			{
				display: inline-block;
				vertical-align: middle;
			}			
		}
		&__flag
		{
			@include deferred
			{
				margin-right: 15px;
			}			
		}
		&__text
		{
			@include deferred
			{
				color: $colorBody;
				font-weight: 500;
				@include font-size(15px);
				@include line-height(23px);
			}
		}
	}
	&__close
	{
		@include deferred
		{
			position: fixed;
			top: 0;
			width: 100%;
			left: 0;
			z-index: 11;
			text-align: right;
		}
	}
}