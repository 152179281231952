﻿@use "../config" as *;
@use "../mixins" as *;

.l-footer {
	@include critical {
		background: $colorBackground;
		color: $colorWhite;
		padding: 45px 10px;
		position: relative;
		text-align: center;
		z-index: 1;

		@include mq-min($bpTablet) {
			padding: 65px 10px;
		}

		@include mq-min($bpDesktop) {
			padding: 75px 10px;
		}
	}

	@include deferred {

		a {

			.js-focus-visible &:focus.focus-visible {
				outline: 4px solid $colorFocusOnDark;
				opacity: 1;
			}
		}
	}

	&__inner {
		@include critical {
			margin: 0 auto;
			text-align: left;
			width: 90%;
			max-width: $maxPageWidth;
		}
	}

	&__content {
		@include deferred {
			font-size: 0;
			line-height: 0;
			margin-left: -35px;

			@include mq-min($bpDesktop) {
				display: flex;
			}
		}
	}

	&__col {
		@include deferred {
			display: inline-block;
			padding: 0 0 40px 35px;
			position: relative;
			vertical-align: top;
			width: 100%;
			z-index: 1;

			@include mq-min($bpTablet) {
				flex: 1;
				flex-basis: 100%;
			}

			@include mq-min($bpDesktop) {
				padding-bottom: 75px;
				flex-basis: 50%;
			}

			&--links {
				@include mq-min($bpTablet) {
					flex-basis: 50%;
					width: 50%;
				}

				@include mq-min($bpDesktop) {
					flex-basis: 25%;
					width: 25%;
				}
			}
		}

		&:before {
			@include deferred {
				border-bottom: 1px solid rgba($colorWhite, .25);
				bottom: 25px;
				content: "";
				display: none;
				left: 35px;
				position: absolute;
				right: 0;
				z-index: 1;

				@include mq-min($bpDesktop) {
					display: block;
				}
			}
		}
	}

	&__language {
		@include deferred {
			padding: 0;
		}

		&__button {
			@include deferred {
				color: $colorWhite;
				background-color: transparent;
				font-size: 0;
				line-height: 0;
				cursor: pointer;
			}

			&:focus {
				@include deferred {
					outline: $colorWhite solid 4px;
				}
			}

			&:hover {
				@include deferred {
					color: $colorGrey;
				}
			}
		}

		&__flag, &__text {
			@include deferred {
				display: inline-block;
				vertical-align: middle;
			}
		}

		&__flag {
			@include deferred {
				@include border-radius(100%);
				height: 24px;
				margin-right: 15px;
				overflow: hidden;
				position: relative;
				width: 24px;
				z-index: 1;
			}

			&__icon {
				@include deferred {
					display: block;
					left: 50%;
					position: absolute;
					top: 50%;
					@include transform(translateX(-50%) translateY(-50%) scale(1.5));
					z-index: 1;
				}
			}
		}

		&__text {
			@include deferred {
				@include font-size(16px);
				@include line-height(18px);

				@include mq-min($bpTablet) {
					@include font-size();
					@include line-height(20px);
				}

				@include mq-min($bpDesktop) {
					@include font-size(18px);
					font-weight: 500;
					@include line-height(22px);
				}
			}
		}
	}

	&__menu {
		&__link {
			@include deferred {
				color: $colorWhite;
				@include font-size(16px);
				font-weight: 500;
				line-height: 1.5;
				padding: 12px 0;

				@include mq-min($bpTablet) {
					@include font-size();
					@include line-height(20px);
				}

				@include mq-min($bpDesktop) {
					@include font-size(18px);
					@include line-height(22px);
				}

				&:hover {
					color: $colorGrey;
				}
			}
		}

		&--secondary {
			@include deferred {
				.l-footer__menu__link {
					color: $colorGrey;
					font-weight: 400;

					&:hover {
						color: $colorWhite;
					}
				}
			}
		}
	}

	&__copyright {
		@include deferred {
			border-top: 1px solid rgba($colorWhite, .25);
			@include font-size(14px);
			@include line-height(20px);
			padding: 20px 0;

			@include mq-min($bpDesktop) {
				border-top: 0;
				float: right;
				padding: 0 0 0 20px;
				width: 50%;

				@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
					padding: 0;
				}
				/* remove padding completely for IE11 due to flexbox issues */
			}
		}
	}

	&__skip {
		@include deferred {
			background: $colorWhite;
			bottom: 0;
			color: $colorBlack !important;
			display: block;
			@include font-size(18px);
			font-weight: 500;
			@include line-height(26px);
			overflow: hidden;
			padding: 8px 15px;
			position: absolute;
			right: 100%;
			text-decoration: underline !important;
			z-index: 8888;
		}

		&:focus {
			@include deferred {
				right: 0;
			}
		}
	}
}

.l-footer__social {

	@include critical {
		margin: 50px 0 0 0;
		@include font-size(16px);
		@include line-height(18px);
		font-weight: 500;

		@include mq-min($bpTablet) {
			@include font-size();
			@include line-height(20px);
		}

		@include mq-min($bpDesktop) {
			@include font-size(18px);
			@include line-height(22px);
		}
	}
}