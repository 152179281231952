﻿@use "../config" as *;
@use "../mixins" as *;

@include deferred
{
	@keyframes search-overlay-line
	{
		0%
		{
			width: 0;
		}
		100%
		{
			width: calc(100% - 55px);
		}
	}
}

.m-search-overlay {
	@include deferred {
		background: $colorBlack;
		margin: 0;
		position: relative;
		width: 100%;
		z-index: 1;
		height: 100%;

		label.error {
			display: none !important;
		}
	}

	&__inner {
		@include deferred {
			margin: 0 auto;
			position: relative;
			width: 90%;
			max-width: $maxPageWidth;
			z-index: 1;
			height: 100%;
			padding-right: 40px;

			@include mq-min($bpDesktop) {
				padding-right: 0;
			}
		}
	}

	&__content {
		@include deferred {
			position: relative;
			top: 100px;

			@include mq-min($bpTablet) {
				top: 140px;
			}

			@include mq-min($bpDesktop) {
				top: 240px;
				max-width: 80%;
			}
			// Quirk
			// Create space at the bottom of the overlay on touch devices so as to not break fixed position on close button when used in combination with text input and autocomplete

			.touchevents & {
				padding-bottom: 1000px;
			}
		}
	}

	&__content__inner {
		@include deferred {
			position: relative;
		}

		&:before {
			@include deferred {
				animation: search-overlay-line .4s ease-out;
				border-top: 1px solid $colorWhite;
				bottom: 0;
				content: "";
				display: block;
				position: absolute;
				width: calc(100% - 55px);
			}
		}
	}

	&__text {
		@include deferred {
			border: 0;
			color: $colorWhite;
			@include font-size(28px);
			height: 55px;
			@include line-height(50px);
			margin-right: 5px !important;
			padding: 0 60px 5px 0;
			@include placeholder-text($colorGrey);
			width: 100%;
			max-width: 100%;

			@include mq-min($bpTablet) {
				@include font-size(40px);
				height: 60px;
			}

			@include mq-min($bpDesktop) {
				@include font-size(64px);
				@include line-height(90px);
				height: 100px;
			}
		}
	}

	&__button {
		@include deferred {
			position: absolute;
			top: 0;
			right: 0;
			height: 55px;
			width: 50px;

			@include mq-min($bpTablet) {
				height: 60px;
			}

			@include mq-min($bpDesktop) {
				height: 100px;
			}

			.js-focus-visible &:focus.focus-visible {
				outline: 4px solid $colorFocusOnDark;
			}
		}

		> svg {
			@include deferred {
				@include mq-min($bpDesktop) {
					width: 100%;
				}
			}
		}
	}
}