﻿@use "../config" as *;
@use "../mixins" as *;

.m-filters
{
	@include critical
	{
		display: none;
	}
	@include deferred
	{
		margin: 0 auto;
		position: relative;

		.mfp-wrap &
		{
			display: block;
		}
	}
	&__inner
	{
		@include deferred
		{
			padding-top: 95px + 20px;
			padding-bottom: 127px + 10px;

			@include mq-min($bpDesktop)
			{
				padding-top: 125px + 30px;
				padding-bottom: 127px + 20px;
			}
		}
	}
	&__title
	{
		@include deferred
		{
			@include font-size(22px);
			height: 95px;
			padding: 0;
			position: fixed;
			left: 0;
			top: 0;
			width: 100%;
			background: $colorWhite;
			z-index: 10;
			border-bottom: 1px solid $colorOffWhite;

			@include mq-min($bpTablet)
			{
				@include font-size(24px);
			}

			@include mq-min($bpDesktop)
			{
				@include font-size(50px);
				height: 125px;
			}

			h2
			{
				padding: 0;
				position: relative;
				top: 50%;
				@include transform(translateY(-50%));
				padding-right: 100px;
			}
		}
	}
	&__title__inner
	{
		@include deferred
		{
			height: 100%;
		}
	}
	&__description
	{
		@include deferred
		{
			padding: 0;

			@include mq-min($bpTablet)
			{
				padding: 0;
			}

			@include mq-min($bpDesktop)
			{
				padding: 0;
			}
		}
	}
	&__action
	{
		@include deferred
		{
			position: fixed;
			bottom: 0;
			background: $colorWhite;
			width: 100%;
			left: 0;
			z-index: 10;
			text-align: center;
			padding: 20px 0;
			border-top: 1px solid $colorOffWhite;

			@include mq-min($bpDesktop)
			{
				padding: 30px 0;
			}
		}
	}
	&__action__inner
	{
		@include deferred
		{
			@include mq-max($bpDesktop - 1)
			{
				position: relative;
				padding-top: 30px;
			}
		}
	}
	&__action__clear
	{
		@include deferred
		{
			float: left;
			min-width: 0;
			width: calc(50% - 6px);
			max-width: none;
			&:not(:last-child)
			{
				margin-right: 0;
			}
			@include mq-min($bpDesktop)
			{
				width: 200px;
			}
		}
	}
	&__action__apply
	{
		@include deferred
		{
			float: right;
			min-width: 0;
			width: calc(50% - 6px);
			max-width: none;
			&:focus
			{
				outline: 4px solid $colorFocusDarkOnLight;
				outline-offset: 1px;
			}
			&:not(:last-child)
			{
				margin-right: 0;
			}
			@include mq-min($bpDesktop)
			{
				width: 200px;
			}
		}
	}
	&__action__count
	{
		@include deferred
		{
			@include mq-max($bpDesktop - 1)
			{
				position: absolute;
				top: -7px;
				left: 0;
				text-align: center;
				width: 100%;
			}
			@include mq-min($bpDesktop)
			{
				@include line-height(66px);
				padding: 0 40px 0 0;
				float: right;
			}
		}
	}
	&__action__loader
	{
		@include deferred
		{
			display: block;
			@include mq-max($bpDesktop - 1)
			{
				position: absolute;
				top: -3px;
				left: 50%;
				margin-left: -10px;
			}
			@include mq-min($bpDesktop)
			{
				margin-right: 40px;
				float: right;
			}
		}
	}
	&__close
	{
		@include deferred
		{
			position: fixed;
			top: 0;
			width: 100%;
			left: 0;
			z-index: 11;
			text-align: right;
		}
	}
	&__group
	{
		@include deferred
		{
			
		}
	}
	&__group__title
	{
		@include deferred
		{
			padding-bottom: 20px;
			@include mq-min($bpTablet)
			{
				padding-bottom: 25px;
			}
		}
	}
}