﻿@use "../config" as *;
@use "../mixins" as *;

.m-block {
	@include critical {
		padding: 40px 0;
		text-align: left;
		border-top: 1px solid $colorDivider;

		@include mq-min($bpTablet) {
			padding: 45px 0;
		}

		@include mq-min($bpDesktop) {
			padding: 65px 0;
		}
	}

	&--small {
		@include critical {
			padding: 40px 0;

			@include mq-min($bpTablet) {
				padding: 40px 0;
			}
		}
	}

	.js-scroll-in {
		.js:not(.prefers-reduce-motion) & {
			@include critical {
				opacity: 0;
				@include transform(translateY(30px));
			}

			&.scroll-in {
				@include critical {
					@include transform(translateY(0));
					opacity: 1;
					@include transition(opacity .3s linear .15s);

					@include mq-min($bpDesktop) {
						@include transition(opacity .3s linear .15s, transform .3s linear .15s);
					}

				}
			}
		}
	}

	&__featured-title {
		@include critical {
			padding-bottom: 29px;
			text-align: center;

			@include mq-min($bpTablet) {
				padding-bottom: 37px;
			}

			@include mq-min($bpDesktop) {
				padding-bottom: 48px;
			}
		}
	}

	&__title {
		@include critical {
			padding-bottom: 10px;

			@include mq-min($bpDesktop) {
				padding-bottom: 15px;
			}
		}

		&:not(.h1) {
			@include critical {
				@include font-size(28px);
				font-weight: 500;
				@include line-height(36px);
				padding-bottom: 15px;

				@include mq-min($bpTablet) {
					@include font-size(32px);
					@include line-height(40px);
					text-align: center;
				}

				@include mq-min($bpDesktop) {
					@include font-size(40px);
					@include line-height(48px);
				}
			}
		}

		&--tight:not(.h1),
		&--tight {
			@include critical {
				padding-bottom: 0;
			}
		}

		&--spaced,
		&--spaced:not(.h1) {
			@include critical {
				padding: 0 0 40px 0;

				@include mq-min($bpTablet) {
					padding: 0 0 45px 0;
				}

				@include mq-min($bpDesktop) {
					padding: 0 0 65px 0;
				}
			}
		}
	}

	&__summary {
		@include critical {
			@include mq-min($bpTablet) {
				text-align: center;
			}
		}
	}

	&__inner--spaced,
	&__summary--spaced {
		@include critical {
			padding: 0 0 40px 0;

			@include mq-min($bpTablet) {
				padding: 0 0 45px 0;
			}

			@include mq-min($bpDesktop) {
				padding: 0 0 65px 0;
			}
		}
	}

	&__inner--spaced-small,
	&__summary--spaced-small {
		@include critical {
			padding: 0 0 40px 0;

			@include mq-min($bpTablet) {
				padding: 0 0 40px 0;
			}
		}
	}

	&__call-to-action {
		@include critical {
			padding: 15px 0 0 0;

			@include mq-min($bpTablet) {
				text-align: center;
			}

			@include mq-min($bpDesktop) {
				padding-top: 20px;
			}
		}
	}

	&__content {
		&--narrow {
			@include critical {
				padding-top: 25px;

				@include mq-min($bpTablet) {
					padding-top: 30px;
				}

				@include mq-min($bpDesktop) {
					padding-top: 35px;
				}
			}
		}

		&--close {
			@include critical {
				padding-top: 0;
			}
		}

		&--search {
			@include critical {
				padding: 10px 0 12px 0;

				@include mq-min($bpTablet) {
					padding: 12px 0 13px 0;
					width: 80%;
				}

				@include mq-min($bpDesktop) {
					padding-bottom: 3px;
				}
			}
		}

		&--tight {
			@include critical {
				padding-bottom: 0;
			}
		}

		&--title-above {
			padding-top: 0;

			@include mq-min($bpTablet) {
				padding-top: 22px;
			}

			@include mq-min($bpDesktop) {
				padding-top: 33px;
			}
		}
	}

	&--off-white {
		@include critical {
			background: $colorOffWhite;
		}
	}

	&--subtle-highlight {
		@include critical {
			background: $colorGreyLighter;
		}
	}

	&--mid-highlight {
		@include critical {
			background: $colorGreyAlt;
		}
	}

	&--dark-highlight {
		@include critical {
			background: #333;
			color: $colorWhite;
		}
	}

	&--search {
		@include critical {
			@include mq-min($bpDesktop) {
				padding-top: 37px;
			}
		}
	}

	&--center {
		@include critical {
			text-align: center;
		}
	}

	&--left-content {
		.m-block__inner {
			@include critical {
				text-align: left;

				.m-block__call-to-action {
					text-align: left;
				}

				@include mq-min($bpTablet) {
					padding-right: 120px;
				}

				@include mq-min($bpDesktop) {
					padding-right: 335px;
				}
			}
		}

		.m-block__title,
		.m-block__summary {
			text-align: left;
		}
	}

	&--collapse-top {
		@include critical {
			padding-top: 0;
			border: 0;
		}
	}

	&--collapse-bottom {
		@include critical {
			padding-bottom: 0;
		}
	}

	&--territory-hub {
		@include critical {
			border-top: 0;
			padding: 24px 0 0 0;

			@include mq-min($bpTablet) {
				padding: 20px 0 0 0;
			}

			@include mq-min($bpDesktop) {
				padding: 32px 0 0 0;
			}
		}
	}

	&--dark {
		@include critical {
			background-color: $colorBaseDark;
			color: $colorWhite;
		}
	}

	&__seperator {
		width: 100%;
		background-color: $colorBaseDark;
		height: 1px;
		overflow: hidden;
		margin-bottom: 40px;

		@include mq-min($bpTablet) {
			margin-bottom: 45px;
		}

		@include mq-min($bpDesktop) {
			margin-bottom: 65px;
			height: 1px;
		}

		&--dark {
			background-color: #666;
		}
	}
}

.m-block--flush {
	padding-bottom: 0; 
}

.m-block--overflow-hidden {
	overflow: hidden;
}

#territory-hub-child-blocks {
	@include critical {
		max-width: 1440px;
		margin: 0 auto;
		padding-bottom: 24px;

		@include mq-min($bpTablet) {
			padding-bottom: 20px;
		}

		@include mq-min($bpDesktop) {
			padding-bottom: 32px;
		}
	}
}

.m-block__image-split {
	@include critical {
		display: flex;
		align-items: flex-end;
	}
}

.m-block__image-split-col {
	@include critical {
		flex-grow: 1;
		flex-basis: 0;
	}
}

.m-block__image-split-col--image {
	@include critical {
		@include mq-max($bpTablet - 1px) {
			position: absolute;
			width: 50%;
			max-width: 200px;
			bottom: -40px;
			right: -10px;
		}
	}
}

.m-block__image-split-image {
	@include critical {
		@include mq-min($bpTablet) {
			margin-bottom: -40px;
			padding-left: 20px;
		}
		@include mq-min($bpDesktop) {
			padding-left: 40px;
		}
	}
}

.m-block--grey-alt-3 {

	@include critical {
		background: $colorGreyAlt3;
	}
}

.m-block--accreditation {

	@include critical {

		.m-block__call-to-action {
			padding-top: 40px;

			@include mq-min($bpDesktop) {
				padding-top: 60px;
			}
		}
	}
}

.m-block--items__title {

	@include critical {

		&:not(.visually-hidden) {
			margin: 0 0 30px 0;
			padding: 0;

			@include mq-min($bpDesktop) {
				margin: 0 0 40px 0;
			}
		}
	}
}