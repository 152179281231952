﻿@use "../config" as *;
@use "../mixins" as *;

.m-loader
{
	@include critical
	{
		display: none;
	}
	@include deferred
	{
		background-repeat: no-repeat;
		background-position: 50% 50%;
		height: 25vh;
		min-height: 300px;
	}
}

.m-loader--filters
{
	@include deferred
	{
		width: 20px;
		height: 20px;
		min-height: 0;
		background-size: 100% auto;
		@include mq-min($bpDesktop)
		{
			width: 30px;
			height: 65px;
		}
	}
}