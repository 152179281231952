﻿@use "../config" as *;
@use "../mixins" as *;

.m-map
{
	@include critical
	{
		background: $colorDivider;
		height: 400px;
		position: relative;
		z-index: 1;
		@include mq-min($bpTablet)
		{
			height: 380px;
		}
		@include mq-min($bpDesktop)
		{
			height: 500px;
		}
	}
	&:before
	{
		@include deferred
		{
			color: $colorGrey;
			content: "Map...";
			left: 10px;
			position: absolute;
			right: 10px;
			text-align: center;
			@include transform(translateY(-50%));
			top: 50%;
			z-index: 1
		}
	}
}