﻿@use "../config" as *;
@use "../mixins" as *;

.l-header {
	@include critical {
		background: $colorBlack;
		color: $colorWhite;
		height: $headerHeightMobile;
		left: 0;
		padding: 0;
		position: fixed;
		right: 0;
		text-align: center;
		top: 0;
		z-index: 1000;

		@include mq-min($bpTablet) {
			height: $headerHeightTablet;
		}

		@include mq-min($bpDesktop) {
			height: $headerHeightDesktop;
		}
	}

	&__inner-container {
		@include critical {
			position: relative;
			z-index: 100;
		}
	}

	&__inner {
		@include critical {
			margin: 0 auto;
			text-align: left;
			width: 90%;
			max-width: $maxPageWidth;
		}
	}

	&__toggle {
		@include critical {
			display: block;
			height: 56px;
			float: left;
			margin-left: -20px;
			position: relative;
			@include text-hidden();
			width: 56px;
			z-index: 1;

			@include mq-min($bpTablet) {
				height: 60px;
				margin-left: -22px;
				width: 60px;
			}

			@include mq-min($bpDesktop) {
				display: none;
			}
		}

		&__icon {
			@include critical {
				background: $colorWhite;
				display: block;
				height: 2px;
				left: 20px;
				position: absolute;
				top: 27px;
				@include transition(all .1s ease-in);
				width: 16px;
				z-index: 1;

				@include mq-min($bpTablet) {
					left: 22px;
					top: 29px;
				}
			}

			&:before, &:after {
				@include critical {
					background: $colorWhite;
					content: "";
					display: block;
					height: 2px;
					position: absolute;
					width: 16px;
					z-index: 1;
				}
			}

			&:before {
				@include critical {
					top: -6px;
					@include transition(top .1s ease .14s, opacity .1s ease);
				}

				[aria-expanded="true"] & {
					@include critical {
						opacity: 0;
						top: 0;
						@include transition(top .1s ease,opacity .1s ease .14s);
					}
				}
			}

			&:after {
				@include critical {
					top: 6px;
					@include transition(top .1s ease .14s, transform .1s ease-in);
				}

				[aria-expanded="true"] & {
					@include critical {
						top: 0;
						@include rotate(-90deg);
						@include transition(top .1s ease, transform .1s ease-out .14s);
					}
				}
			}

			[aria-expanded="true"] & {
				@include critical {
					@include rotate(45deg);
					@include transition(all .1s ease-out .14s);
				}
			}
		}
	}

	&__logo {
		@include critical {
			float: left;
			height: 56px;
			margin-right: 2px;
			padding-top: 15px;
			max-width: 64px;

			@include mq-min($bpTablet) {
				height: 60px;
				padding-top: 18px;
			}

			@include mq-min($bpDesktop) {
				height: 64px;
				padding-top: 16px;
				max-width: 74px;
			}
		}

		@include deferred {

			.js-focus-visible &:focus.focus-visible {
				position: relative;
				outline: 4px solid $colorFocusOnDark;
			}
		}

		> svg {
			@include critical {
				display: inline-block;
				fill: $colorWhite;
				height: 24px;
				margin: 0;
				vertical-align: middle;
				width: auto;

				@include mq-min($bpTablet) {
					height: 26px;
				}

				@include mq-min($bpDesktop) {
					height: 28px;
				}
			}
		}
	}

	&__search {
		@include critical {
			float: right;
			height: 56px;
			margin-right: -10px;
			position: relative;
			width: 50px;
			z-index: 1;

			@include mq-min($bpTablet) {
				height: 60px;
				width: 60px;
			}

			@include mq-min($bpDesktop) {
				height: 64px;
				width: 64px;
			}

			@include mq-min($bpNavSearch) {
				margin: 0 0 0 auto;
				padding-top: 15px;
				width: 238px;
			}
		}

		&__text {
			@include critical {
				border: 0;
				border-bottom: 1px solid $colorWhite;
				color: $colorWhite;
				display: none;
				float: left;
				@include font-size();
				height: 28px;
				padding: 0;
				@include line-height(28px);
				margin-right: 2px !important;
				@include placeholder-text($colorWhite);
				width: calc(100% - 28px);

				@include mq-min($bpNavSearch) {
					display: block;
				}
			}
		}

		&__button {
			@include critical {
				float: right;
				height: 54px;
				margin-top: 1px;
				width: 50px;

				> svg {
					@include critical {
						width: 20px;
					}
				}

				@include mq-min($bpTablet) {
					height: 58px;
				}

				@include mq-min($bpDesktop) {
					height: 60px;
				}

				@include mq-min($bpNavSearch) {
					height: 26px;
					margin-top: 2px;
					width: 26px;
				}
			}
		}

		&__overlay {
			@include critical {
				display: none;
			}

			@include deferred {
				background: transparent;
				border: 0;
				bottom: 0;
				cursor: pointer;
				left: 0;
				margin: 0;
				overflow: hidden;
				padding: 0;
				position: absolute;
				right: 0;
				text-indent: -9999px;
				top: 0;
				width: 100%;
				z-index: 2;
				color: $colorWhite;

				@include focus-hide() {
					outline: none;
				}

				.js-focus-visible &:focus.focus-visible {
					outline: 4px solid $colorFocusOnDark;
				}
			}
		}
	}

	&__spacer {
		@include critical {
			height: $headerHeightMobile;

			@include mq-min($bpTablet) {
				height: $headerHeightTablet;
			}

			@include mq-min($bpDesktop) {
				height: $headerHeightDesktop;
			}
		}
	}

	@include deferred {
		transition: transform .5s cubic-bezier(.68,.03,.26,1.02);

		&.is-hidden {
			transform: translate3d(0px, $headerHeightMobile * -1, 0px);

			@include mq-min($bpTablet) {
				transform: translate3d(0px, $headerHeightTablet * -1, 0px);
			}

			@include mq-min($bpDesktop) {
				transform: translate3d(0px, $headerHeightDesktop * -1, 0px);
			}
		}
	}
}