﻿@use "../config" as *;
@use "../mixins" as *;
@use "sass:math";

.l-nav {
	@include critical {
		background: $colorBlack;
		bottom: 0;
		color: $colorWhite;
		display: none;
		left: 0;
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;
		padding-bottom: 100px;
		position: fixed;
		right: 0;
		top: 56px;
		z-index: 8000;

		@include mq-min($bpTablet) {
			top: 60px;
		}

		@include mq-min($bpDesktop) {
			bottom: auto;
			display: block !important;
			float: left;
			left: auto;
			overflow: visible;
			padding-bottom: 0;
			position: static;
			right: auto;
			top: auto;
			max-width: calc(100% - 114px);
		}
	}

	&__inner {
		@include critical {
			margin: 0 auto;
			width: 90%;
			max-width: $maxPageWidth;

			@include mq-min($bpDesktop) {
				width: auto;
				max-width: 100%;
			}
		}
	}

	&__menu {
		@include critical {
			@include mq-min($bpDesktop) {
				padding-left: 24px;
			}

			@include mq-min($bpDesktopLarge) {
				padding-left: 48px;
			}
		}

		&__item {
			@include critical {
				@include mq-min($bpDesktop) {
					display: inline-block;
					margin: 0 !important;
					opacity: 1 !important;
					padding: 0;
					vertical-align: top;
				}

				@include mq-min($bpDesktopLarge) {
					padding: 0 2px;
				}
			}

			.js & {
				@include critical {
					margin-left: -20px;
					margin-right: 20px;
					opacity: 0;
					will-change: opacity;

					@for $i from 1 through 20 {
						&:nth-child(#{$i}) {
							$delay: $i * .05s;
							@include transition(opacity .3s linear $delay, margin .3s linear $delay);
						}
					}
				}
			}

			&.fade-in {
				@include critical {
					margin-left: 0;
					margin-right: 0;
					opacity: 1;
				}
			}
		}

		&__link {
			@include critical {
				border-bottom: 1px solid $colorGrey;
				color: $colorWhite;
				display: block;
				@include font-size(18px);
				@include line-height(26px);
				padding: 24px 0;
				background: none;
				margin: 0;
				cursor: pointer;
				@include appearance-none();
				width: 100%;
				text-align: left;

				@include mq-min($bpDesktop) {
					border: 0;
					@include font-size();
					height: 64px;
					@include line-height();
					text-align: center;
					padding: 20px 14px;
					width: auto;
				}
			}

			@include deferred {

				@include focus-hide() {
					outline: none;
				}

				.js-focus-visible &:focus.focus-visible {
					outline: 4px solid $colorFocusOnDark;
				}

				&:hover {
					@include critical {
						color: $colorGrey;
					}
				}
			}

			&[aria-expanded] {
				@include critical {
					padding-right: 20px;
					position: relative;
					z-index: 1;

					@include mq-min($bpDesktop) {
						padding-right: 14px;
					}
				}

				&:before {
					@include critical {
						border: 2px solid $colorWhite;
						border-left-width: 0;
						border-top-width: 0;
						content: "";
						display: block;
						height: 9px;
						position: absolute;
						right: 5px;
						@include rotate(45deg);
						top: 28px;
						width: 9px;
						z-index: 1;

						@include mq-min($bpDesktop) {
							display: none;
						}
					}
				}
			}

			&[aria-expanded="true"] {
				@include critical {
					@include mq-min($bpDesktop) {
						background: $colorNavDivider;
						color: $colorWhite;
					}
				}

				&:before {
					@include critical {
						@include rotate(-135deg);
						top: 33px;
					}
				}
			}
		}

		&__link--faux {
			@include critical {
				&:hover {
					@include critical {
						color: $colorWhite;
					}
				}
			}
		}
	}

	&__sub {
		@include critical {
			display: none;
			overflow: hidden;

			@include mq-min($bpDesktop) {
				background: $colorNavDivider;
				max-height: calc(100vh - 64px);
				left: 0;
				overflow-y: auto;
				-webkit-overflow-scrolling: touch;
				position: absolute;
				right: 0;
				@include transition(none);
				top: 100%;
			}
		}

		&__menu {
			@include deferred {
				font-size: 0;
				line-height: 0;

				@include mq-min($bpDesktop) {
					left: -15px;
					margin: 0 auto;
					padding: 20px 0 45px 0;
					position: relative;
					right: -15px;
					width: 90%;
					max-width: $maxPageWidth;
					z-index: 1;
				}
			}

			&__item {
				@include deferred {
					@include mq-min($bpDesktop) {
						display: inline-block;
						padding-bottom: 15px;
						vertical-align: top;
						width: 25%;
						max-width: 25%;
					}

					@for $i from 1 through 4 {
						&:nth-last-child(#{$i}), &:nth-last-child(#{$i}) ~ li {
							@include mq-min($bpDesktop) {
								width: (math.div(100, $i) * 1%);
							}
						}
					}
				}
			}

			&__link {
				@include deferred {
					border-bottom: 1px solid $colorNavDivider;
					color: $colorWhite;
					display: block;
					@include font-size();
					font-weight: 300;
					@include line-height();
					padding: 24px 0;

					@include mq-min($bpDesktop) {
						border-bottom-color: $colorBase;
						margin: 0 15px;
						padding: 16px 0;
					}

					.js-focus-visible &:focus.focus-visible {
						outline: 4px solid $colorFocusOnDark;
					}
				}

				&:hover {
					@include deferred {
						color: $colorGrey;
					}
				}

				&--with-children {
					&[aria-expanded] {
						@include deferred {
							padding-right: 20px;
							position: relative;
							z-index: 1;

							@include mq-min($bpDesktop) {
								padding-right: 14px;
							}
						}

						&:before {
							@include deferred {
								border: 2px solid $colorWhite;
								border-left-width: 0;
								border-top-width: 0;
								content: "";
								display: block;
								height: 9px;
								position: absolute;
								right: 5px;
								@include rotate(45deg);
								top: 28px;
								width: 9px;
								z-index: 1;

								@include mq-min($bpDesktop) {
									display: none;
								}
							}
						}
					}

					&[aria-expanded="true"] {
						@include deferred {
							@include mq-min($bpDesktop) {
								background: $colorNavDivider;
								color: $colorWhite;
							}
						}

						&:before {
							@include deferred {
								@include rotate(-135deg);
								top: 33px;
							}
						}
					}
				}
			}
		}
	}

	&__sub-sub {
		@include deferred {
			display: none;

			@include mq-min($bpDesktop) {
				display: block;
			}
		}

		&__menu {
			@include deferred {
				margin: 0 15px;
				padding-top: 8px;
			}

			&__link {
				@include deferred {
					color: $colorWhite;
					display: block;
					@include font-size();
					font-weight: 300;
					@include line-height();
					padding: 6px 0;

					.js-focus-visible &:focus.focus-visible {
						outline: 4px solid $colorFocusOnDark;
					}
				}

				&:hover {
					@include deferred {
						color: $colorGrey;
					}
				}
			}
		}
	}
}