﻿@use "../config" as *;
@use "../mixins" as *;

.l-breadcrumbs {
	@include critical {
		background: $colorGreyDark;
		color: $colorWhite;
		padding: 8px 10px;
		text-align: center;
	}

	&__inner {
		@include critical {
			margin: 0 auto;
			text-align: left;
			width: 90%;
			max-width: $maxPageWidth;
		}
	}

	&__menu {
		@include deferred {
			font-size: 0;
			line-height: 0;
			margin-left: -10px;
		}

		&__item {
			@include deferred {
				display: inline-block;
				@include font-size(14px);
				@include line-height(22px);
			}

			@include deferred {
				position: relative;
				z-index: 1;
			}

			&:before {
				@include deferred {
					border-left: 1px solid rgba($colorWhite, .4);
					content: "";
					display: none;
					height: 24px;
					margin: 0;
					padding: 0;
					position: absolute;
					right: 9px;
					@include rotate(30deg);
					top: 9px;
					z-index: 1;
				}
			}

			&:not(:last-child) {
				@include deferred {
					padding-right: 20px;
				}

				&:before {
					@include deferred {
						display: block;
					}
				}
			}
		}

		&__link {
			@include deferred {
				color: $colorWhite;
				padding: 10px;
				text-decoration: none;
			}

			&:hover,
			&:focus {
				@include deferred {
					color: rgba($colorWhite, .6);
				}
			}

			@include deferred {
				@include focus-hide() {
					outline: none;
				}

				.js-focus-visible &:focus.focus-visible {
					outline: 4px solid $colorFocusOnDark;
				}
			}

			&.active {
				@include deferred {
					cursor: default;
				}

				&:hover {
					@include deferred {
						color: $colorWhite;
					}
				}
			}
		}
	}
}