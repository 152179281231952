﻿@use "sass:color";
@use "../config" as *;
@use "../mixins" as *;

.m-applied-filters
{
	@include critical
	{
		display: none;
	}
	@include deferred
	{
		display: block;
	}
	&__title
	{
		@include deferred
		{
			padding: 17px 0;
			font-weight: 500;
			@include mq-min($bpTablet)
			{
				padding: 23px 0 19px 0;
			}
		}
	}
	&__menu
	{
		@include deferred
		{
			font-size: 0;
			line-height: 0;
			margin: 0;
			padding: 0;
			@include mq-min($bpTablet)
			{
				float: left;
			}
		}
		&__item
		{
			@include deferred
			{
				float: left;
				@include font-size(14px);
				@include line-height(28px);
				padding: 20px 0 0 20px;
				vertical-align: top;
				padding: 0;

				@include mq-min($bpDesktop)
				{
					@include font-size(16px);
				}

				&:not(:first-child) {
					padding-left: 20px;
					@include mq-min($bpTablet)
					{
						padding-left: 24px;
					}
				}
			}
		}
		&__item--with-remove
		{
			.m-applied-filters__menu__label__inner
			{
				@include deferred
				{
					padding-right: 32px;
				}
			}
		}
		&__label
		{
			@include deferred
			{
				position: relative;
				z-index: 1;
			}
		}
		&__label__inner
		{
			@include deferred
			{
				border: 1px solid $colorGreyLight;
				display: block;
				padding: 0 15px;
				background: $colorGreyLighter;
				@include transition(border 0.25s ease, background 0.25s ease);
				margin: 0;
				-webkit-appearance: none;
				-moz-appearance: none;
				appearance: none;
				cursor: pointer;

				.js-focus-visible &:focus.focus-visible {
					outline: 4px solid $colorFocus;
				}

				&:hover
				{
					border-color: $colorNoticeDark;
					background: $colorNoticeDark;
				}
			}
		}
		&__text
		{
			@include deferred
			{
				font-style: normal;
				color: $colorBody;
			}
		}
		&__remove
		{
			@include deferred
			{
				display: block;
				position: absolute;
				@include text-hidden();
				right: 0;
				top: 50%;
				width: 32px;
				height: 30px;
				@include background-fill();
				@include transform(translateY(-50%));

				&:hover,
				&:focus
				{
					&:before,
					&:after
					{
						border-color: color.adjust($colorBody, $lightness: 30%);
					}
				}
			}			
			&:before, &:after
			{
				@include deferred
				{
					border-left: 2px solid $colorBody;
					content: "";
					display: block;
					height: 12px;
					left: 14px;
					margin: 0;
					padding: 0;
					position: absolute;
					top: 9px;
					width: 0;
					z-index: 1;
				}
			}
			&:before
			{
				@include deferred
				{
					@include rotate(-45deg);
				}
			}
			&:after
			{
				@include deferred
				{
					@include rotate(45deg);
				}
			}
			&:focus, &:hover
			{
			
			}
		}
	}
	&__early-careers
	{
		@include deferred
		{
			padding: 20px 0 0 0;
			@include mq-min($bpTablet)
			{
				float: left;
				padding: 0 0 0 24px;
			}
		}
	}
	&__clear
	{
		@include deferred
		{
			padding: 15px 0 0 0;
		}
		&__link
		{
			@include deferred
			{
				color: $colorBody;
				text-decoration: underline;
				-webkit-appearance: none;
				-moz-appearance: none;
				appearance: none;
				background: none;
				margin: 0;
				padding: 0;
				border: 0;
				cursor: pointer;
			}
			
			.js-focus-visible &:focus.focus-visible {
				outline: 4px solid $colorFocus;
			}
			
			&:hover
			{
				@include deferred
				{
					color: $colorBody;
					text-decoration: none;
				}
			}
		}
	}
}