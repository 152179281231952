﻿@use "../config" as *;
@use "../mixins" as *;

.c-share {

  @include critical {
    padding: 30px 0 0 0;
    text-align: center;

    @include mq-min($bpDesktop) {
      padding: 40px 0 0 0;
    }

    .c-cols__primary & {
      margin: 30px 0 0 0;
      border-top: 1px solid $colorDivider;

      @include mq-min($bpTablet) {
        text-align: left;

        .c-share__list {
          justify-content: flex-start;
        }
      }

      @include mq-min($bpDesktop) {
        margin: 60px 0 0 0;
        padding: 60px 0 0 0;
      }
    }
  }
}

.c-share__inner {

  @include critical {

    .webshareapi & {
      min-height: 100px;

      @include mq-min($bpDesktop) {
        min-height: 110px;
      }
    }
  }
}

.c-share__title {
  
  @include critical {
    margin: 0 0 15px 0;
    padding: 0;
    font-weight: 500;
    
    .webshareapi & {
      @include hide();
    }
  }
}

.c-share__list {

  @include critical {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: center;

    .webshareapi & {
      margin: 0 0 30px 0;
    }
  }
}

.c-share__item {
  
  @include critical {
    
    &:not(:first-child) {
      margin: 0 0 0 15px;
    } 
  }
}

.c-share__item,
.c-share__icon,
.c-share__link {
  
  @include critical {
    width: 30px;
    height: 30px;
    display: block;
    
    @include mq-min($bpDesktop) {
      width: 40px;
      height: 40px;
    }
  }
}

.c-share__link {

  @include deferred {
    
    &:hover,
    &:focus {
      opacity: 0.75;
    }
  }
}

.c-share__link--facebook {
  
  @include critical {
    color: #3b5998;
  }

  @include deferred {

    &:hover,
    &:focus {
      color: #3b5998;
    }
  }
}

.c-share__link--twitter {

  @include critical {
    color: #1DA1F2;
  }

  @include deferred {

    &:hover,
    &:focus {
      color: #1DA1F2;
    }
  }
}

.c-share__link--linkedin {

  @include critical {
    color: #0a66c2;
  }

  @include deferred {

    &:hover,
    &:focus {
      color: #0a66c2;
    }
  }
}

.c-share__link--email {

  @include critical {
    color: $colorGreen;
  }

  @include deferred {

    &:hover,
    &:focus {
      color: $colorGreen;
    }
  }
}

.c-share--dark {

  .c-share__link {

    @include critical {
      color: $colorBaseDark;
    }

    @include deferred {

      &:hover,
      &:focus {
        opacity: 1;
        color: $colorBlackLight;
      }
    }
  }
}