@use "../config" as *;
@use "../mixins" as *;
@use "sass:color";

.js-tool-tip {
	@include critical {
		display: none;
	}

	@include deferred {
		position: absolute;
		@include transform(translate(-50%, -100%));
		will-change: transform, margin, opacity;
		@include transition(margin 0.25s ease, opacity 0.25s ease);
		margin-top: 20px;
		opacity: 0;
		@include font-size(15px);
		@include line-height(15px);
		font-weight: 500;

		@include mq-min($bpDesktop) {
			@include font-size(20px);
			@include line-height(20px);
		}
	}

	&__inner {
		@include deferred {
			position: relative;
			padding: 18px 15px;
			background: $colorWhite;
			box-shadow: 0px 5px 5px 0px rgba(102,102,102,0.15);
		}

		&:before,
		&__arrow-one {
			@include deferred {
				content: '';
				display: block;
				width: 0;
				height: 0;
				border-left: 21px solid transparent;
				border-right: 21px solid transparent;
				border-top: 21px solid rgba(102,102,102,0.05);
				position: absolute;
				bottom: -21px;
				left: 50%;
				margin-left: 0;
				@include transform(translateX(-50%));
				will-change: margin-left;
			}
		}

		&:after,
		&__arrow-two {
			@include deferred {
				content: '';
				display: block;
				width: 0;
				height: 0;
				border-left: 20px solid transparent;
				border-right: 20px solid transparent;
				border-top: 20px solid $colorWhite;
				position: absolute;
				bottom: -19px;
				left: 50%;
				margin-left: 0;
				@include transform(translateX(-50%));
				will-change: margin-left;
			}
		}
	}

	&__close {
		@include deferred {
			width: 30px;
			height: 30px;
			text-align: left;
			text-indent: -9999px;
			@include background-fill();
			top: 17px;
			right: 19px;
			cursor: pointer;
			position: absolute;

			&:before, &:after {
				border-left: 2px solid $colorBaseDark;
				content: "";
				display: block;
				height: 24px;
				margin: 0;
				padding: 0;
				position: absolute;
				right: 14px;
				top: 3px;
				width: 0;
				z-index: 1;
			}

			&:before {
				@include rotate(-45deg);
			}

			&:after {
				@include rotate(45deg);
			}

			@include focus-hide() {
				outline: none;
			}

			.js-focus-visible &:focus.focus-visible {
				outline: 4px solid $colorFocus;
			}

			&:hover {
				@include background-fill();

				&:before,
				&:after {
					border-left: 2px solid color.adjust($colorBaseDark, $lightness: 5%);
				}
			}
		}
	}

	&--visible {
		@include deferred {
			display: block;
		}
	}

	&--in {
		@include deferred {
			margin-top: 0;
			opacity: 1;
		}
	}

	&--physical-arrow {
		@include deferred {
			.js-tool-tip__inner {
				&:before,
				&:after {
					display: none;
				}
			}
		}
	}

	&--disable-animations {
		@include deferred {
			@include transition(none !important);
		}
	}
}
