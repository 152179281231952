﻿@use "../config" as *;
@use "../mixins" as *;

.m-feature-block {

	&__inner {
		@include critical {
			position: relative;
			z-index: 1;
		}
	}

	&__image-link {
		@include critical {
			display: block;
		}
	}

	&__picture {
		@include critical {
			margin-bottom: 18px;
		}
	}

	&__title {
		@include critical {
			@include font-size(24px);
			@include line-height(28px);
			padding: 0;

			@include mq-min($bpTablet) {
				@include font-size(24px);
				@include line-height(32px);
			}

			@include mq-min($bpDesktop) {
				@include font-size(28px);
				@include line-height(36px);
			}
		}

		&--small {
			@include critical {
				@include font-size();
				font-weight: 500;
				@include line-height();

				@include mq-min($bpDesktop) {
					@include font-size(20px);
					@include line-height(28px);
				}
			}
		}

		&--divider {
			@include critical {
				border-bottom: 1px solid $colorBase;
				margin: 0 0 15px 0;
			}
		}
	}

	&__summary {
		@include critical {
			padding-top: 6px;
		}

		&--small {
			@include critical {
				@include font-size();
				@include line-height();
			}
		}
	}

	&__action {
		@include critical {
			padding: 15px 0 0 0;
		}
	}

	&__cta-overlay {
		@include deferred {
			.js & {
				background: $colorWhite;
				max-width: 750px;
				width: 90%;
				margin: 0 auto;
				position: relative; // for popup close button positioning
				display: none;
			}

			.js .mfp-content & {
				display: block;
			}

			.js .mfp-wrap.mfp-no-close-text & .mfp-close {
				top: 0;

				@include mq-min($bpDesktop) {
					top: 18px;
				}
			}
		}
	}

	&__cta-overlay__content {
		@include deferred {
			.js & {
				padding: 15px 15px;

				@include mq-min($bpTablet) {
					padding: 20px;
				}

				@include mq-min($bpTabletLarge) {
					padding: 24px 40% 24px 24px;
				}

				.cms *:last-child {
					padding-bottom: 0;
				}
			}
		}
	}

	&__cta-overlay__title {
		@include deferred {
			.js & {
				padding-right: 30px;
				line-height: 1;
				@include font-size(22px);

				@include mq-min($bpDesktop) {
					padding-right: 100px;
					@include font-size(28px);
				}
			}
		}
	}

	&__cta-overlay__dropdown-row {
		@include deferred {
			padding: 20px 0;

			@include mq-min($bpDesktop) {
				padding: 30px 0;
			}
		}
	}
}