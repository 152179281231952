﻿@use "../config" as *;
@use "../mixins" as *;

.m-jobs-list
{
	@include deferred
	{
		padding-top: 23px;
		@include mq-min($bpTablet)
		{
			width: 66.6666%;
			width: calc(66.6666% - 8px);
			max-width: $maxContentWidth;
			padding-top: 30px;
		}
		@include mq-min($bpDesktop)
		{
			width: 100%;
			max-width: $maxFormWidth;
			max-width: 56.641%;
			padding-top: 40px;
		}

		.m-block--job-listing & {
			padding-top: 0;
		}
	}
	&__header
	{
		@include deferred
		{
			padding-bottom: 10px;
			@include mq-min($bpTablet)
			{
				max-width: 640px;
				@include font-size(20px);
				@include line-height(28px);
				padding-bottom: 20px;
			}
			@include mq-min($bpDesktop)
			{
				@include font-size(28px);
				@include line-height(28px);
				padding-bottom: 35px;
			}
		}
		&--hidden
		{
			@include deferred
			{
				@include hide();
			}
		}
	}
	&__title
	{
		@include deferred
		{
			padding-bottom: 0;
		}
	}
	&__meta
	{
		@include deferred
		{
			color: $colorBaseDark;
			padding-bottom: 0;
			font-weight: 500;

			.m-block--job-listing &
			{
				font-weight: normal;
				@include mq-min($bpDesktop)
				{
					@include font-size(24px);
					@include line-height(24px);
				}
			}
		}
		&:not(:first-child)
		{
			@include deferred
			{
				padding-top: 10px;
			}
		}
	}
	&__sort
	{
		@include deferred
		{
			padding-bottom: 13px;
			@include mq-min($bpDesktop)
			{
				padding-bottom: 22px;
			}
		}
	}
	&__sort__inner
	{

	}
	&__sort__title
	{
		@include deferred
		{
			padding: 0 0 15px 0;
			color: $colorBaseDark;

			@include mq-min($bpTablet)
			{
				float: left;
				padding: 0 20px 0 0;
				@include font-size(20px);
				@include line-height(29px);
			}

			@include mq-min($bpDesktop)
			{
				@include font-size(24px);
			}
		}
	}
	&__sort__list
	{
		@include deferred
		{

			@include mq-min($bpTablet)
			{
				float: left;
			}
		}
	}
	&__menu
	{
		@include deferred
		{
			margin: 0 -5.5%;
			@include mq-min($bpTablet)
			{
				margin: 0;
			}
		}
		&__item
		{
			@include deferred
			{
				padding: 0 5% 64px 5%;
				position: relative;
				z-index: 1;
				@include mq-min($bpTablet)
				{
					padding: 0 0 72px 0;
				}
				.m-block--job-listing &:last-child
				{
					padding-bottom: 32px;
					@include mq-min($bpTablet)
					{
						padding-bottom: 36px;
					}
					&:before
					{
						display: none;
					}
				}
			}
			&:before
			{
				@include deferred
				{
					border-top: 1px solid $colorDivider;
					bottom: 33px;
					display: block;
					content: "";
					left: 0;
					position: absolute;
					width: 100%;
					max-width: $maxContentWidth;
					z-index: 1;
					@include mq-min($bpTablet)
					{
						bottom: 37px;
					}
				}
			}
		}
	}
	&__action
	{
		&--prev
		{
			@include deferred
			{
				.m-show-more__action {
					padding-bottom: 24px;
				}
			}
		}
		&--next
		{
			@include deferred
			{
				padding-top: 24px;
				@include mq-min($bpTablet)
				{
					padding-top: 18px;
				}
			}
		}

		.form-button
		{
			@include deferred
			{
				width: 100%;
				max-width: 100%;
				min-width: 0;
				@include mq-min($bpTablet)
				{
					width: 48.5%;
					max-width: 48.5%;
				}
				@include mq-min($bpDesktop)
				{
					width: 56%;
					max-width: 56%;
				}
				&:focus
				{
					outline: 4px solid $colorFocusDarkOnLight !important;
					outline-offset: 1px;
				}
			}
		}
	}
}