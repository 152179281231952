﻿@use "../config" as *;
@use "../mixins" as *;

.m-job-preview
{
	&__title
	{
		@include deferred
		{
			padding-bottom: 5px;
			font-weight: 400;
			@include mq-min($bpTablet)
			{
				padding-bottom: 10px;
			}
		}
	}
	&__content
	{
		@include deferred
		{
			position: relative;
			z-index: 1;
		}
	}
	&__team
	{
		@include deferred
		{
			color: $colorGreyAlt2;
			@include font-size(20px);

			@include mq-min($bpDesktop)
			{
				@include font-size(24px);
			}
		}
	}
	&__market
	{
		@include deferred
		{
			font-weight: 500;
		}
	}
	&__date
	{
		@include deferred
		{
			font-weight: 500;
		}
	}
	&__faux-link
	{
		@include deferred
		{
			bottom: 0;
			left: 0;
			position: absolute;
			right: 0;
			top: 0;
			@include text-hidden();
			z-index: 1;
		}
	}
}