﻿@use "../config" as *;
@use "../mixins" as *;

.c-cookie-notice {

	@include critical {
		opacity: 0;
		position: fixed;
		bottom: -9999px;
		transition: opacity 0.5s ease;
		text-align: center;
		overflow: hidden;
		flex-direction: column;
	}

	@include deferred {
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba($colorBlack, 0.75);
		z-index: 1001;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.c-cookie-notice__inner {

	@include deferred {
		max-width: 900px;
		margin: 0 auto;
		padding: 0 30px;
		width: 100%;
	}
}

.c-cookie-notice__inner__inner {

	@include deferred {
		background: $colorWhite;
		padding: 30px;
		max-height: 80vh;
		overflow: auto;
		box-shadow: 0px 0px 50px 0px rgba($colorBlack,0.75);

		@include mq-min($bpDesktop) {
			padding: 40px;
		}
	}
}

.c-cookie-notice__title {

	@include deferred {
		margin: 0 0 15px 0;
		padding: 0;
		font-weight: 500;
		@include font-size(16px);
		@include line-height(26px);

		@include mq-min($bpDesktop) {
			@include font-size(20px);
			@include line-height(28px);
		}
	}
}

.c-cookie-notice__description,
.c-cookie-notice__categories__description {

	@include deferred {
		@include font-size(16px);
		@include line-height(24px);

		p {
			margin: 0;
			padding: 0;
		}

		a {
			text-decoration: underline;
			color: $colorBody;

			&:hover {
				text-decoration: none;
				color: $colorBody;
			}
		}
	}
}

.c-cookie-notice__actions {

	@include deferred {
		margin: 20px 0 0 0;

		@include mq-min($bpDesktop) {
			margin: 30px 0 0 0;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
}

.c-cookie-notice__action {

	@include deferred {
		padding: 0;

		&:not(:first-child) {
			margin: 15px 0 0 0;

			@include mq-min($bpDesktop) {
				margin: 0 0 0 15px;
			}
		}
	}
}

.c-cookie-notice__stage {

	@include deferred {
		display: none;
	}
}

.c-cookie-notice__stage--active {

	@include deferred {
		display: block;
	}
}

.c-cookie-notice__categories {

	@include deferred {
		margin: 20px 0 0 0;
		padding: 0;
		list-style: none;
		text-align: left;

		@include mq-min($bpDesktop) {
			margin: 30px 0 0 0;
		}
	}
}

.c-cookie-notice__categories__item {

	@include deferred {

		&:not(:first-child) {
			margin: 20px 0 0 0;
		}
	}
}

.c-cookie-notice__categories__title {

	@include deferred {
		margin: 0 0 5px 0;
	}
}

.c-cookie-notice__categories__title__label {

	@include deferred {
		display: inline-block;
		position: relative;
		cursor: pointer;
		overflow: hidden;
		line-height: 34px;
		height: 34px;
		padding: 0 0 0 70px;
		font-weight: 500;
		@include font-size(16px);

		@include focus-hide() {
			outline: none;
		}

		.js-focus-visible &:focus.focus-visible {
			outline: 4px solid $colorFocus;
		}

		&:before {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			height: 34px;
			width: 60px;
			background: $colorBase;
			transition: background 0.25s ease;
			border-radius: 34px;
		}

		&:after {
			content: '';
			display: block;
			position: absolute;
			height: 26px;
			width: 26px;
			left: 4px;
			top: 4px;
			background: $colorWhite;
			transition: transform 0.25s ease;
			border-radius: 50%;
		}

		@include mq-min($bpDesktop) {
			@include font-size(20px);
		}

		&.is-disabled {
			cursor: not-allowed;
		}
	}
}

.c-cookie-notice__categories__title__checkbox {

	@include deferred {
		position: absolute;
		opacity: 0.01;
		left: -100px;

		.js-focus-visible &:focus.focus-visible + .c-cookie-notice__categories__title__label {
			outline: 4px solid $colorFocus;
		}

		&:checked + .c-cookie-notice__categories__title__label {

			&:before {
				background: $colorGreen;
			}

			&:after {
				transform: translateX(26px);
			}
		}
	}
}

.c-cookie-notice--show {

	@include deferred {
		opacity: 1;
	}
}
