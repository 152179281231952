﻿@use "config" as *;
@use "mixins" as *;

.cms
{
	a:not(.form-button)
	{
		@include critical
		{
			color: inherit;
			text-decoration: underline;
		}
		&:hover
		{
			@include critical
			{
				color: inherit;
				text-decoration: none;
			}
		}
	}
	ul, ol
	{
		@include critical
		{
			padding-bottom: 15px;
			padding-left: 30px;
		}
		> li
		{
			@include critical
			{
				position: relative;
			}
		}
	}
	ul
	{
		> li
		{
			&:before
			{
				@include critical
				{
					background: $colorBody;
					@include border-radius(100%);
					content: "";
					display: block;
					height: 6px;
					left: -17px;
					margin: 0;
					padding: 0;
					position: absolute;
					top: 11px;
					width: 6px;
					z-index: 1;
				}
			}
		}
	}
	ol
	{
		@include critical
		{
			counter-reset: cms-ol;
		}
		> li
		{
			@include critical
			{
				counter-increment: cms-ol;
			}
			&:before
			{
				@include critical
				{
					color: $colorBody;
					content: counter(cms-ol)".";
					display: block;
					left: -30px;
					margin: 0;
					padding: 0;
					position: absolute;
					text-align: right;
					top: 0;
					width: 25px;
					z-index: 1;
				}
			}			
		}
	}
	picture, img
	{
		@include critical
		{
			margin: 0 auto 15px auto;
			padding: 0;
		}		
	}
	figure, p
	{
		picture, img
		{
			@include critical
			{
				margin-bottom: 0;
			}			
		}
	}
	figure
	{
		@include critical
		{
			color: $colorGreyDark;
			display: inline-block;
			@include font-size(12px);
			font-style: italic;
			@include line-height(20px);
			margin: 0 auto;
			padding: 0 0 15px 0;
		}
		figcaption
		{
			@include critical
			{
				background: $colorOffWhite;
				display: block;
				margin: 0;
				padding: 8px 10px;
			}
		}
	}
	iframe
	{
		@include critical
		{
			display: inline-block;
			margin: 0 0 15px 0;
			padding: 0;
			max-width: 100%;
		}		
	}
	.m-responsive-video
	{
		@include critical
		{
			margin: 0 0 15px 0;
		}
	}
	blockquote
	{
		@include critical
		{
			color: $colorBody;
			@include font-size(24px);
			font-weight: 400;
			@include line-height(32px);
			margin: 0;
			padding: 0;
			@include mq-min($bpTablet)
			{
				@include font-size(28px);
				@include line-height(36px);
			}
			@include mq-min($bpDesktop)
			{
				@include font-size(32px);
				@include line-height(40px);
			}
		}
		p 
		{
			&:not(:last-child)
			{
				@include critical
				{
					padding-bottom: 5px;
				}
			}
		}
		a
		{
			@include critical
			{
				color: $colorBody;
				text-decoration: underline;
			}
			&:hover
			{
				color: $colorBody;
				@include critical
				{
					text-decoration: none;
				}
			}
		}
		.quote-author
		{
			@include critical
			{
				color: $colorGrey;
				display: block;
				@include font-size();
				@include line-height();
				padding-left: 18px;
				@include mq-min($bpTablet)
				{
					@include font-size(18px);
					@include line-height(26px);
				}
				@include mq-min($bpDesktop)
				{
					@include font-size(20px);
					@include line-height(28px);
				}
			}
			@include deferred
			{
				position: relative;
				z-index: 1;
			}
			a
			{
				@include critical
				{
					color: $colorGrey;
				}
				&:hover
				{
					@include critical
					{
						color: $colorGrey;
					}
				}
			}
			&:before
			{
				@include deferred
				{
					border-top: 2px solid $colorGreyLight;
					content: "";
					left: 0;
					position: absolute;
					top: 15px;
					width: 10px;
					z-index: 1;
					@include mq-min($bpTablet)
					{
						top: 11px;
					}
					@include mq-min($bpDesktop)
					{
						top: 13px;
					}
				}
			}
		}
	}
	table
	{
		@include critical
		{
			border-bottom: 1px solid $colorDivider;
			margin: 0 0 15px 0;
			padding: 0;
		}
		th, td
		{
			@include critical
			{
				margin: 0;
				padding: 8px 10px;
				text-align: left;
				vertical-align: top;
			}
		}
		thead
		{
			th, td
			{
				@include critical
				{
					color: $colorGrey;
					@include font-size(12px);
					@include line-height(20px);
					text-transform: uppercase;
				}
			}
		}
		tbody, tfoot
		{
			tr
			{
				th, td
				{
					@include critical
					{
						border-top: 1px solid $colorDivider;
						@include font-size(14px);
						@include line-height(22px);
						@include mq-min($bpDesktop)
						{
							@include font-size();
							@include line-height();
						}
					}
				}
			}
		}
		tbody
		{
			tr
			{
				&:nth-child(odd)
				{
					th, td
					{
						@include critical
						{
							background: rgba($colorBlack, .02);
						}
					}
				}
			}
		}
	}
	&-table
	{
		@include deferred
		{
			margin: 0 0 15px 0;
			overflow-x: auto;
			padding: 0 0 10px 0;
			-webkit-overflow-scrolling: touch;
			@include mq-min($bpDesktop)
			{
				margin: 0;
				overflow-x: visible;
				padding: 0;
			}
		}
		table
		{
			@include deferred
			{
				@include mq-max($bpTablet - 1)
				{
					margin: 0;
					min-width: $bpMobileLarge;
				}
			}
		}
	}
	&-video
	{
		@include critical
		{
			background: $colorBlack;
			height: 0;
			margin: 0 auto 15px auto;
			overflow: hidden;
			padding: 56.25% 0 0 0;
			position: relative;
			z-index: 1;
		}
		iframe
		{
			@include critical
			{
				display: block;
				height: 100%;
				left: 0;
				margin: 0;
				padding: 0;
				position: absolute;
				top: 0;
				width: 100%;
				z-index: 1;
			}			
		}
	}
	.button
	{
		&:not(a)
		{
			@include critical
			{
				display: block;
			}			
		}
	}
	a.button, .button a, a .button
	{
		@include critical
		{
			background: $colorPrimary;
			border: 0;
			@include border-radius(0);
			color: $colorWhite;
			cursor: pointer;
			display: inline-block;
			@include font-size();
			font-weight: 500;
			@include line-height(20px);
			margin: 0;
			overflow: hidden;
			padding: 18px 15px;
			position: relative;
			text-align: center;
			text-decoration: none;
			@include user-select();
			vertical-align: middle;
			white-space: nowrap;
			width: 100%;
			max-width: 154px;
			z-index: 1;
			@include mq-min($bpDesktop)
			{
				padding: 22px 15px;
				max-width: 184px;
			}
		}
		&:before
		{
			@include critical
			{
				background: $colorPrimaryDark;
				@include border-radius(100%);
				content: "";
				display: block;
				height: 0;
				left: 50%;
				opacity: 0;
				position: absolute;
				top: 50%;
				@include transform(translateX(-50%) translateY(-50%));
				@include transition(opacity .5s, width 1s, height 1s, background .5s);
				width: 0;
				z-index: -1;
			}
		}
		&:not(:last-child)
		{
			@include critical
			{
				margin-right: 15px;
			}		
		}
		&:hover, &:active
		{
			@include critical
			{
				background: $colorPrimary;
				color: $colorWhite;
			}
			&:before
			{
				@include critical
				{
					height: 500px;
					left: 50%;
					opacity: 1;
					width: 500px;
				}
			}
		}
		&:active
		{
			animation: button-press .3s;
		}
	}
	&--light
	{
		@include critical
		{
			color: $colorWhite;
		}
	}
	&--restrict
	{
		@include critical
		{
			max-width: $maxContentWidth;
		}
	}
}

.cms--tight {

	> * {

		&:last-child {
			padding: 0;
		}
	}
}

.cms--image-full
{
	picture,
	img
	{
		@include critical
		{
			max-width: 100%;
			width: 100%;
			display: block;
		}
	}
}

.cms--center {
	text-align: center;
}

.cms--small {
	@include font-size(15px);
	@include line-height(20px);
}

.cms--flush {

	> * {
		padding: 0;
	}
}

.cms--blocks__block--image,
.cms--blocks__block--video,
.cms--blocks__block--action
{
	@include critical
	{
		padding: 15px 0;
		&:first-child
		{
			padding-top: 0;
		}
		&:last-child
		{
			padding-bottom: 0;
		}

		+ .cms--blocks__block--image,
		+ .cms--blocks__block--video,
		+ .cms--blocks__block--action
		{
			padding-top: 8px;
		}
	}
}