﻿@use "config" as *;
@use "mixins" as *;

// fade in
.js .js-fade-in
{
	.js-fade-in-item
	{
		@include critical
		{
			opacity: 0;
			will-change: opacity;
			@for $i from 1 through 20
			{
				&:nth-child(#{$i})
				{
					$delay: $i * .15s;
					@include transition(opacity .3s linear $delay);
				}
			}
		}
		&.fade-in
		{
			@include critical
			{
				opacity: 1;
			}
		}
	}
	.js-fade-in-item--no-delay
	{
		@include critical
		{
			transition-delay: 0ms !important;
		}
	}
}

.html--slow-connection,
.prefers-reduce-motion
{
	.js-fade-in
	{
		.js-fade-in-item
		{
			@include critical
			{
				opacity: 1 !important;
			}
		}
	}

	.js-scroll-in
	{
		@include critical
		{
			@include transition(transform .3s linear .15s, opacity .3s linear .15s);
			@include transform(#{translateY(0)} !important);
			opacity: 1 !important;
		}
	}
}

// image grow
.image-grow
{
	@include deferred
	{
		overflow: hidden;
		position: relative;
		z-index: 1;
	}
	.image-grow-image
	{
		@include deferred
		{
			@include transition(transform .3s linear);
			will-change: transform;
		}
	}
	&:hover, .image-grow-hover:hover &
	{
		.image-grow-image
		{
			@include transform(scale(1.025));
		}
		.image-grow-image--lots
		{
			@include transform(scale(1.15));
		}
	}
}

.prefers-reduce-motion .image-grow:hover,
.prefers-reduce-motion .image-grow-hover:hover,
.prefers-reduce-motion .m-image-action:hover {
	.image-grow-image {
		@include transform(scale(1));
	}
}

// will change
.will-change
{
	@include critical
	{
		&-opacity
		{
			will-change: opacity;
		}
		&-transform
		{
			will-change: transform;
		}
		&-opacity-transform
		{
			will-change: opacity, transform;
		}
	}
}

// containers
.max-width-90
{
	@include critical
	{
		margin: 0 auto;
		width: 90%;
	}
}

@include mq-min($bpTablet) {

	.max-width-66 {
		@include critical {
			width: 66.66%;
		}
	}

}

.max-width-50--all {
	@include critical {
		width: 50%;
	}
}

.max-page-width
{
	@include critical
	{
		@include mq-min($bpDesktop)
		{
			max-width: $maxPageWidth;
		}
	}
}

.max-content-width
{
	@include critical
	{
		@include mq-min($bpDesktop)
		{
			max-width: $maxContentWidth;
		}
	}
}

.max-form-width
{
	@include critical
	{
		@include mq-min($bpTablet)
		{
			max-width: $maxFormWidth;
		}
	}
}

// visibility
.offscreen
{
	@include critical
	{
		border: 0;
		clip: rect(0, 0, 0, 0);
		height: 1px;
		margin: -1px;
		overflow: hidden;
		padding: 0;
		position: absolute;
		width: 1px;
	}	
}

.hide
{
	@include critical
	{
		display: none;
	}	
}

.hide-on-mobile
{
	@include critical
	{
		@include mq-max($bpMobileLarge - 1)
		{
			display: none;
		}
	}
}

.hide-on-tablet
{
	@include critical
	{
		@include mq-min($bpTablet)
		{
			@include mq-max($bpTabletLarge - 1)
			{
				display: none;
			}
		}
	}
}

.hide-on-tablet-or-smaller
{
	@include critical
	{
		@include mq-max($bpTablet - 1)
		{
			display: none;
		}
	}
}

.hide-on-tablet-or-larger
{
	@include critical
	{
		@include mq-min($bpTablet)
		{
			display: none;
		}
	}
}

.hide-on-desktop
{
	@include critical
	{
		@include mq-min($bpDesktop)
		{
			@include mq-max($bpDesktopLarge - 1)
			{
				display: none;
			}
		}
	}
}

.hide-on-desktop-or-smaller
{
	@include critical
	{
		@include mq-max($bpDesktop - 1)
		{
			display: none;
		}
	}
}

.hide-on-desktop-or-larger
{
	@include critical
	{
		@include mq-min($bpDesktop)
		{
			display: none;
		}
	}
}

// js
.hide-on-js
{
	.js &
	{
		@include critical
		{
			display: none;
		}
	}
}

.show-on-js
{
	.no-js &
	{
		@include critical
		{
			display: none;
		}
	}
}

// alignment
.align
{
	&-left
	{
		@include critical
		{
			text-align: left;
		}	
	}
	&-right
	{
		@include critical
		{
			text-align: right;
		}	
	}
	&-centre, &-center
	{
		@include critical
		{
			text-align: center;
		}	
	}
	&-justify
	{
		@include critical
		{
			text-align: justify;
		}	
	}
}

// text
.text
{
	@include critical
	{
		&-success
		{
			color: $colorSuccess;
		}
		&-error
		{
			color: $colorError;
		}
		&-warning
		{
			color: $colorWarning;
		}
	}
}

// case
.uppercase
{
	@include critical
	{
		text-transform: uppercase;
	}
}

// visuallyhidden

.visually-hidden
{
	@include critical
	{
		position: absolute; 
		overflow: hidden; 
		clip: rect(0 0 0 0); 
		height: 1px; width: 1px; 
		margin: -1px; padding: 0; border: 0;
	}
}