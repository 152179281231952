﻿@use "../config" as *;
@use "../mixins" as *;

.autocomplete {
	@include deferred {
		background: $colorWhite;
		color: $colorGreyDark;
		margin: 0;
		padding: 10px 0;
		border: 1px solid $colorBase;
		border-top: 0;
		text-align: left;

		[data-autocomplete-container] & {
			left: auto !important;
			top: auto !important;
		}

		.m-generic-search-block & {
			position: static !important;
			border: none;
			padding-bottom: 0;
		}

		.m-search-overlay & {
			background: none;
			color: $colorWhite;
			border: 0;
			padding: 20px 55px 0 0;

			@include mq-min($bpTablet) {
				padding: 20px 55px 0 0;
			}

			@include mq-min($bpDesktop) {
				padding: 20px 55px 0 0;
			}
		}

		.autocomplete__item {
			cursor: pointer;
			padding: 7px 16px;
			background: none;
			display: block;
			margin: 0;
			text-decoration: none;
			text-align: left;
			border: 0;
			vertical-align: top;
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
			color: $colorGreyDark;
			width: 100%;
			font-family: $fontBase;

			&.selected,
			.no-touchevents &:hover {
				background: $colorSecondary;
			}

			.no-touchevents .m-search-overlay &:hover,
			.m-search-overlay &.selected {
				background: $colorBaseDark;
			}

			.m-search-overlay & {
				color: $colorWhite;
			}

			em {
				font-weight: 500;
				font-style: normal;
				color: $colorGreyDark;

				.m-search-overlay & {
					color: $colorWhite;
				}
			}
		}
	}
}
