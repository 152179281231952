﻿@use "../config" as *;
@use "../mixins" as *;

@include deferred {

	.m-carousel__arrow {
		background: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		height: 2.75rem;
		width: 2.75rem;
		position: absolute;
		top: 75px;
		pointer-events: auto;
		z-index: 9999;
		animation: fadeIn 2s ease-in forwards 1s;
		background-color: rgba(255,255,255,0.6);
		border: none;
		border-radius: 100%;
		transition: background-color 300ms;

		@include mq-min($bpTablet) {
			top: 100px;
		}

		@include mq-min($bpDesktop) {
			top: 50%;
			width: 3rem;
			height: 3rem;
		}
	}

	.m-carousel__arrow {

		&:hover {
			background-color: rgba(255,255,255,0.8);
		}

		@include focus-hide() {
			outline: none;
		}

		.js-focus-visible &:focus.focus-visible {
			outline: 4px solid $colorFocus;
		}
	}

	.m-carousel__arrow-icon {
		fill: #333;
		height: .75rem;
		width: .75rem;

		@include mq-min($bpDesktop) {
			height: 1.125rem;
			width: 1.125rem;
		}
	}

	.m-carousel__arrow--prev {
		transform: translate(0,-50%) rotate(90deg);
		left: 0;
	}

	.m-carousel__arrow--next {
		transform: translate(0,-50%) rotate(270deg);
		right: 0;
	}

	.m-carousel__arrow--dark {
		background-color: rgba(51,51,51,0.6);
	}

	.m-carousel__arrow--dark.slick-disabled {
		background-color: rgba(51,51,51,0.4);
		cursor: default;
	}

	.m-carousel__arrow--dark:not(.slick-disabled):hover {
		background-color: rgba(51,51,51,0.8);
	}

	.m-carousel__arrow--dark .m-carousel__arrow-icon {
		fill: $colorWhite;
	}

	.m-carousel__dots {
		position: absolute;
		list-style: none;
		left: 50%;
		transform: translateX(-50%);
		padding: 20px 0 0 0;
		z-index: 102;
		margin: 0;
		display: flex !important; /* The carousel plugin overrides the display to block :-( */
		overflow-x: hidden
	}

	.m-carousel__dots li {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 1rem;
		width: 1rem;
		margin: 0 .375rem;
		padding: 0;
		cursor: pointer
	}

	.m-carousel__dots li.slick-active button:before {
		top: 0;
		left: 0;
		border-radius: 50%;
		height: 1rem;
		width: 1rem
	}

	.m-carousel__dots button {
		border: 0;
		background: transparent;
		display: block;
		height: 1rem;
		width: 1rem;
		line-height: 0;
		font-size: 0;
		color: transparent;
		padding: .25rem;
		cursor: pointer;
		border-radius: 50%;
		pointer-events: none
	}

	.m-carousel__dots button:before {
		content: "";
		position: absolute;
		border-radius: 50%;
		height: .5rem;
		width: .5rem;
		top: 25%;
		left: 25%
	}

	.m-carousel__dots.no-focus li button {
		outline: 0
	}

	.m-carousel__dots.theme--light li button:before {
		background-color: $colorBaseDark;
		opacity: .2
	}

	.m-carousel__dots.theme--light li.slick-active button:before,
	.m-carousel__dots.theme--light li button:before {
		transition: background-color .4s linear,opacity .4s linear
	}

	.m-carousel__dots.theme--light li.slick-active button:before {
		opacity: .5
	}

	.m-carousel__dots.theme--medium li button:before {
		background-color: $colorWhite;
		opacity: .4
	}

	.m-carousel__dots.theme--medium li.slick-active button:before,
	.m-carousel__dots.theme--medium li button:before {
		transition: background-color .4s linear,opacity .4s linear
	}

	.m-carousel__dots.theme--medium li.slick-active button:before {
		opacity: .7
	}

	.m-carousel__dots.theme--dark li button:before {
		opacity: .3;
		background-color: $colorWhite
	}

	.m-carousel__dots.theme--dark li.slick-active button:before,
	.m-carousel__dots.theme--dark li button:before {
		transition: background-color .4s linear,opacity .4s linear
	}

	.m-carousel__dots.theme--dark li.slick-active button:before {
		opacity: .6
	}
}