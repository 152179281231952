﻿@use "../config" as *;
@use "../mixins" as *;
@use "sass:math";

$col: math.div(100, 12); // 12 cols

$gutter: 12px;
$gutterTablet: 20px;
$gutterDesktop: 32px;

$gutterLPB: 58px;
$gridLargeMarginBottomMobile: 40px;
$gridLargeMarginBottomTablet: 48px;
$gridLargeMarginBottomDesktop: 64px;

@mixin getWidth($cols)
{
	width: (($col * $cols) * 1%);
}

@mixin getOffset($cols)
{
	margin-left: (($col * $cols) * 1%);
}

.m-grid
{
	@include critical
	{
		clear: both;
		display: block;
		margin: 0 auto;
		padding: 0;
		position: relative;
		width: 100%;
		z-index: 0;
	}
	&__inner
	{
		@include critical
		{
			display: block;
			margin: 0;
			margin-left: -$gutter;
			padding: 0;
			@include mq-min($bpTablet)
			{
				margin-left: -$gutterTablet;
			}
			@include mq-min($bpDesktop)
			{
				margin-left: -$gutterDesktop;
			}
		}
	}
	&__row
	{
		@include critical
		{
			font-size: 0;
			line-height: 0;
			margin: 0;
			padding: 0;
			position: relative;
			text-align: center;
			z-index: 1;
		}
		&--reverse
		{
			@include critical
			{
				direction: rtl;
			}
		}
		&--reverse-tablet
		{
			@include critical
			{
				@include mq-min($bpTablet)
				{
					direction: rtl;
				}
			}
		}
		&--align-left
		{
			@include critical
			{
				text-align: left;
			}
		}
	}
	&__col
	{
		@include critical
		{
			direction: ltr;
			display: inline-block;
			@include font-size();
			@include line-height();
			margin: 0 0 $gutter 0;
			padding: 0 0 0 $gutter;
			text-align: left;
			vertical-align: top;
			width: 100%;
			@for $i from 1 through 11
			{
				&--#{$i}
				{
					@include getWidth($i);
				}
			}
			@include mq-min($bpTablet)
			{
				margin-bottom: $gutterTablet;
				padding-left: $gutterTablet;
				@for $i from 1 through 11
				{
					&--#{$i}--tablet
					{
						@include getWidth($i);
					}
				}
			}
			@include mq-min($bpDesktop)
			{
				@include font-size(20px);
				@include line-height(28px);
				margin-bottom: $gutterDesktop;
				padding-left: $gutterDesktop;
				@for $i from 1 through 11
				{
					&--#{$i}--desktop
					{
						@include getWidth($i);
					}
				}
			}
		}
		&--tight
		{
			@include critical
			{
				margin-bottom: 0;
				padding-left: 0;
			}			
		}
	}
	&--content, &--tight
	{
		@include critical
		{
			margin-bottom: -$gutter;
			@include mq-min($bpTablet)
			{
				margin-bottom: -$gutterTablet;
			}
			@include mq-min($bpDesktop)
			{
				margin-bottom: -$gutterDesktop;
			}
		}
	}
	&--lower-page-blocks
	{
		@include deferred
		{
			margin-bottom: -$gutterLPB;
			@include mq-max($bpTablet - 1)
			{
				margin-bottom: -20px;
			}
		}
		> .m-grid__inner
		{
			> .m-grid__row
			{
				> .m-grid__col
				{
					@include deferred
					{
						margin-bottom: $gutterLPB;
						@include mq-max($bpTablet - 1)
						{
							margin-bottom: 20px;
						}
					}
				}
			}
		}
	}
	&--strip-last-row-3-up-tablet
	{
		@include critical
		{
			> .m-grid__inner
			{
				> .m-grid__row
				{
					> .m-grid__col
					{
						&:last-child
						{
							@include mq-max($bpTablet - 1)
							{
								margin-bottom: 0;

								.m-feature-block
								{
									padding-bottom: 0;
								}
							}
						}
						&:nth-last-child(-n+3)
						{
							@include mq-min($bpTablet)
							{
								margin-bottom: 0;

								.m-feature-block
								{
									padding-bottom: 0;
								}
							}
						}
					}
				}
			}
		}
	}
	&--spaced-bottom-tablet-up
	{
		@include critical
		{
			@include mq-min($bpTablet)
			{
				margin-bottom: -$gutterLPB;
			}
		}
		> .m-grid__inner
		{
			> .m-grid__row
			{
				> .m-grid__col
				{
					@include critical
					{
						@include mq-min($bpTablet)
						{
							margin-bottom: $gutterLPB;
						}
					}
				}
			}
		}
	}
	&--dyson-people {
		@include critical
		{
			margin-bottom: -30px;
			@include mq-min($bpTablet)
			{
				margin-bottom: -40px;
			}
			@include mq-min($bpDesktop)
			{
				margin-bottom: -50px;
			}
		}
		> .m-grid__inner
		{
			@include critical
			{
				margin-left: -12px;
				@include mq-min($bpTablet)
				{
					margin-left: -20px;
				}
			}
			> .m-grid__row
			{
				> .m-grid__col
				{
					@include critical
					{
						margin: 0 0 30px 0;
						padding: 0 0 0 12px;
						@include mq-min($bpTablet)
						{
							margin: 0 0 40px 0;
							padding: 0 0 0 20px;
						}
						@include mq-min($bpDesktop)
						{
							margin: 0 0 50px 0;
						}
					}
				}
			}
		}
	}
	&--featured-blocks {
		@include critical
		{
			margin-bottom: -24px;
			@include mq-min($bpTablet)
			{
				margin-bottom: -20px;
			}
			@include mq-min($bpDesktop)
			{
				margin-bottom: -32px;
			}
		}
		> .m-grid__inner
		{
			@include critical
			{
				margin-left: -24px;
				@include mq-min($bpTablet)
				{
					margin-left: -20px;
				}
				@include mq-min($bpDesktop)
				{
					margin-left: -32px;
				}
			}
			> .m-grid__row
			{
				> .m-grid__col
				{
					@include critical
					{
						margin: 0 0 24px 0;
						padding: 0 0 0 24px;
						@include mq-min($bpTablet)
						{
							margin: 0 0 20px 0;
							padding: 0 0 0 20px;
						}
						@include mq-min($bpDesktop)
						{
							margin: 0 0 32px 0;
							padding: 0 0 0 32px;
						}
					}
				}
			}
		}
	}
}

.m-grid--spaced
{
	@include critical
	{
		.m-grid__col
		{
			margin-bottom: $gutterDesktop;
			@include mq-min($bpTablet)
			{
				margin-bottom: $gutterDesktop;
			}
			@include mq-min($bpDesktop)
			{
				margin-bottom: $gutterDesktop;
			}
			&--large-bottom {
				@include critical
				{
					margin-bottom: $gridLargeMarginBottomMobile;
					@include mq-min($bpTablet)
					{
						margin-bottom: $gridLargeMarginBottomTablet;
					}
					@include mq-min($bpDesktop)
					{
						margin-bottom: $gridLargeMarginBottomDesktop;
					}
				}
			}
		}
		&.m-grid--tight
		{
			margin-bottom: -$gutterDesktop;
		}
	}
}

.m-grid--spaced-horizontal {

	@include critical {

		.m-grid__inner {
			margin-left: -75px;

			@include mq-min($bpDesktop) {
				margin-left: -100px;
			}
		}

		.m-grid__col {
			padding-left: 75px;

			@include mq-min($bpDesktop) {
				padding-left: 100px;
			}
		}
	}
}

.m-grid--spaced-large {

	@include critical {

		.m-grid__col {
			margin-bottom: 40px;

			@include mq-min($bpDesktop) {
				margin-bottom: 75px;
			}
		}

		&.m-grid--tight {
			margin-bottom: -40px;

			@include mq-min($bpDesktop) {
				margin-bottom: -75px;
			}
		}
	}
}