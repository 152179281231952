﻿@use "../config" as *;
@use "../mixins" as *;

.sticky-filters {

	@include deferred {
		z-index: 10;
		position: relative;
	}
}

.sticky-filters--stuck {

	@include deferred {
		position: sticky;
		left: 0;
		width: 100%;
		background: $colorWhite;
		transform: translateY(-100%);
		
		// all top values represent the height of the closed site header
		
		top: 56px;

		@include mq-min($bpTablet) {
			top: 60px;
		}

		@include mq-min($bpDesktop) {
			top: 64px;
		}
	}
}

.sticky-filters--can-animate {

	@include deferred {
		background: $colorWhite;
		transition: transform 0.5s ease;
	}
}

.sticky-filters--visible {

	@include deferred {
		transform: translateY(0);
	}
}