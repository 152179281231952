﻿@use "../config" as *;
@use "../mixins" as *;

.m-banner {
	@include critical {
		height: 500px;
		position: relative;
		z-index: 1;

		&:has(.m-banner__content--colour-light) {
			background-color: $colorBlack;
		}

		@include mq-min($bpTablet) {
			height: 650px;
		}

		@include mq-min($bpDesktop) {
			height: 568px;
		}
	}

	&__inner {
		@include critical {
			height: 100%;
			margin: 0 auto;
			position: relative;
			text-align: left;
			width: 90%;
			max-width: $maxPageWidth;
			z-index: 3;
		}
	}

	&__content {
		@include critical {
			left: 0;
			margin: 0 auto;
			padding: 0;
			position: absolute;
			top: 24px;
			width: 100%;
			max-width: $maxPageWidth;
			z-index: 2;

			@include mq-min($bpTablet) {
				top: 34px;
			}

			@include mq-min($bpDesktop) {
				top: 44px;
			}
		}

		&--position {
			&-top {
				&-middle {
					@include critical {
						left: 50%;
						@include transform(translateX(-50%));
					}
				}

				&-right {
					@include critical {
						left: auto;
						right: 0;
					}
				}
			}

			&-middle {
				&-left {
					@include critical {
						margin-top: -20px;
						top: 50%;
						@include transform(translateY(-50%));
					}
				}

				&-middle {
					@include critical {
						left: 50%;
						margin-top: -20px;
						top: 50%;
						@include transform(translateX(-50%) translateY(-50%));
					}
				}

				&-right {
					@include critical {
						left: auto;
						margin-top: -20px;
						right: 0;
						top: 50%;
						@include transform(translateY(-50%));
					}
				}
			}

			&-bottom {
				&-left {
					@include critical {
						bottom: 24px;
						top: auto;

						@include mq-min($bpTablet) {
							bottom: 34px;
						}

						@include mq-min($bpDesktop) {
							bottom: 44px;
						}
					}
				}

				&-middle {
					@include critical {
						bottom: 24px;
						left: 50%;
						top: auto;
						@include transform(translateX(-50%));

						@include mq-min($bpTablet) {
							bottom: 34px;
						}

						@include mq-min($bpDesktop) {
							bottom: 44px;
						}
					}
				}

				&-right {
					@include critical {
						bottom: 24px;
						left: auto;
						right: 0;
						top: auto;

						@include mq-min($bpTablet) {
							bottom: 34px;
						}

						@include mq-min($bpDesktop) {
							bottom: 44px;
						}
					}
				}
			}
		}

		&--colour {
			&-light {
				@include critical {
					color: $colorWhite;
				}

				@include deferred {

					a {

						.js-focus-visible &:focus.focus-visible {
							outline: 4px solid $colorFocusOnDark;
						}
					}
				}
			}
		}

		&--width {
			&-two-thirds-width {
				@include critical {
					@include mq-min($bpTablet) {
						max-width: 66.6666%;
					}
				}
			}

			&-half-width {
				@include critical {
					@include mq-min($bpTablet) {
						max-width: 50%;
					}
				}
			}

			&-one-third-width {
				@include critical {
					@include mq-min($bpTablet) {
						max-width: 50%;
					}

					@include mq-min($bpDesktop) {
						max-width: 33.3333%;
					}
				}
			}
		}

		&.js-scroll-in {
			.js:not(.prefers-reduce-motion) & {
				@include critical {
					margin-top: 30px;
					opacity: 0;
				}

				&.scroll-in {
					@include critical {
						@include transition(margin-top .3s linear .15s, opacity .3s linear .15s);
						margin-top: 0;
						opacity: 1;
					}
				}
			}
		}

		&__cta {
			padding: 0;
		}
	}

	&__title {
		@include critical {
			@include font-size(40px);
			@include line-height(48px);
			padding-bottom: 10px;

			@include mq-min($bpTablet) {
				@include font-size(48px);
				@include line-height(56px);
			}

			@include mq-min($bpDesktop) {
				@include font-size(64px);
				@include line-height(72px);
			}
		}
	}

	&__summary {
		@include critical {
			@include mq-min($bpDesktop) {
				@include font-size(20px);
				@include line-height(28px);
			}
		}
	}

	&__image {
		@include critical {
			bottom: 0;
			left: 0;
			position: absolute;
			right: 0;
			top: 0;
			z-index: 1;
		}
	}

	&__call-to-action {
		@include critical {
			height: 100%;
			left: 0;
			padding: 0;
			position: absolute;
			top: 0;
			width: 100%;
			z-index: 3;
		}
	}

	&__link {
		@include critical {
			display: block;
			height: 100%;
			left: 0;
			position: absolute;
			@include text-hidden();
			top: 0;
			width: 100%;
			z-index: 1;
		}

		&--video {
			@include critical {
				background: $colorBlack;
				background: rgba($colorBlack, .5);
				@include border-radius(100%);
				color: $colorWhite;
				display: block;
				@include font-size(24px);
				height: 96px;
				left: 50%;
				@include line-height(32px);
				margin: -48px 0 0 -48px;
				padding: 64px 10px 0 10px;
				position: absolute;
				top: 50%;
				@include transition(background .3s linear);
				width: 96px;
				text-align: center;

				&:before {
					@include critical {
						background: url(data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAADgAAABUCAQAAAB9uTy0AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAACxIAAAsSAdLdfvwAAAAHdElNRQfiBggKKDSs0hKvAAABZ0lEQVRo3r3awa3CMBCEYYsjpfBSFJ1RFIJOOA9HeCS2d70zkxTwS5+SyPG64YYzmu9uAO64eIPAC1dvEICL9hM00X4HLbT/gwbafVBMexSU0h4HhbT9oIh2FJTQjoMC2nmQTPsbvOKlpf0NNmx4KGn3wYYzbjrao2BD09H2gjLafnBE+6cJSmhnQTrtPEimjQSptNEgjTYeJNFmghTabLBMmw8WaVeCJdrV4DLtenCRthJcoq0G07T1YJKWEUzRsoING54RWl4wSMsMhmjZwSktPzihVQSHtKfmvoykT2wK0gvuzofG+loMKfnBCSU7aP14hyh5wSAlK2hdYqQo68EkZTVoXQgvUa4HFylXg9bftRJlPlikzAatmwoUyniQRBkNWre+qJTzIJlyFrRu0Eoo+0ERZS9oHyPIKONB6yjIPOx6MCnnQevA0jySlVD2g9axuvnggJRyH7Qe/jAfb7FQfoLmI0pvbx8aQEN5EOIAAAAASUVORK5CYII=) no-repeat 50% 50%;
						@include background-size(100%);
						content: "";
						display: block;
						height: 42px;
						left: 50%;
						margin: -21px 0 0 -10px;
						position: absolute;
						top: 50%;
						width: 28px;
						z-index: 1;

						@include mq-min($bpDesktop) {
							height: 27px;
							margin: 0 0 0 -7px;
							top: 28px;
							width: 18px;
						}
					}
				}

				@include mq-min($bpDesktop) {
					height: 116px;
					margin: -58px 0 0 -58px;
					@include remove-text-hidden();
					@include text-overflow();
					width: 116px;
				}
			}

			&:hover {
				@include critical {
					background: rgba($colorBlack, .75);
					color: $colorWhite;
				}
			}

			@include deferred {
				@include focus-hide() {
					outline: none;
				}

				.js-focus-visible &:focus.focus-visible {
					outline: 4px solid $colorFocus;
				}
			}
		}
	}
}

.m-banner + .m-block {

	@include critical
	{
		border-top: 0;
	}
}