@use "../config" as *;
@use "../mixins" as *;
@use "sass:color";

.m-mapbox
{
	&__wrapper
	{
		@include critical
		{
			position: relative;
			height: 500px;
			background: $colorGreyLighter;
		}
	}
	&__map
	{
		@include critical
		{
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}
	&__pin
	{
		@include deferred
		{
			background-image: url(../images/mapbox-pin.svg);
			background-repeat: no-repeat;
			background-position: 0 0;
			background-size: 100% 100%;
			width: 50px;
			height: 50px;
			display: block;
			cursor: pointer;
			margin-top: -25px;

			@include focus-hide() {
				outline: none;
			}

			.js-focus-visible &:focus.focus-visible {
				outline: 4px solid $colorFocus;
			}
		}
	}
	&__popup
	{
		&__focus-loop
		{
			@include critical
			{
				@include hide();
			}
		}
	}
	.mapboxgl-popup-close-button
	{
		@include deferred
		{
			border-radius: 0;
			width: 30px;
			height: 30px;
			background: red;
			text-align: left;
			text-indent: -9999px;
			@include background-fill();
			@include transition(background 0.25s ease);
			top: 17px;
			right: 19px;

			&:before, &:after
			{
				border-left: 2px solid $colorBaseDark;
				content: "";
				display: block;
				height: 24px;
				margin: 0;
				padding: 0;
				position: absolute;
				right: 14px;
				top: 3px;
				width: 0;
				z-index: 1;
			}
			&:before
			{
				@include rotate(-45deg);
			}
			&:after
			{
				@include rotate(45deg);
			}

			&:hover
			{
				@include background-fill();
				&:before,
				&:after
				{
					border-left: 2px solid color.adjust($colorBaseDark, $lightness: 20%);
				}
			}
			
			@include focus-hide() {
				outline: none;
			}

			.js-focus-visible &:focus.focus-visible {
				outline: 4px solid $colorFocus;
			}
		}
	}
	.mapboxgl-popup-content
	{
		@include deferred
		{
			padding: 18px 24px 9px 24px;
			text-align: left;
			border-radius: 0;
			width: 300px;
			@include font-size();
			@include line-height();
			box-shadow: 0px 5px 5px 0px rgba(102,102,102,0.15);
			@include mq-min($bpDesktop)
			{
				width: 380px;
			}
			h4
			{
				padding-right: 40px;
				@include mq-min($bpDesktop)
				{
					padding: 0 0 20px 0;
					padding-right: 45px;
				}
			}
		}
	}
	.mapboxgl-popup-tip
	{
		@include deferred
		{
			border: 26px solid transparent;
			border-top-color: #fff;
		}
	}
	@include deferred {
		.mapboxgl-ctrl-icon {
			padding: 22px;
		}
	}
}