﻿@use "../config" as *;
@use "../mixins" as *;

.m-play-button
{
	@include deferred
	{
		position: relative;
		display: block;

		@include focus-hide() {
			outline: none;
		}

		.js-focus-visible &:focus.focus-visible {
			outline: 4px solid $colorFocus;
		}
	}
}

.m-play-button__button
{
	@include deferred
	{
		background: $colorBlack;
		background: rgba($colorBlack, .5);
		@include border-radius(100%);
		color: $colorWhite;
		display: block;
		@include font-size(24px);
		height: 96px;
		left: 50%;
		@include line-height(32px);
		margin: -48px 0 0 -48px;
		padding: 64px 10px 0 10px;
		position: absolute;
		top: 50%;
		@include transition(background .3s linear);
		width: 96px;
		@include text-hidden();
		text-align: center;

		.m-feature-block__inner & {
			z-index: 2;
		}

		&:before
		{
			@include deferred
			{
				background: url(data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAADgAAABUCAQAAAB9uTy0AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAACxIAAAsSAdLdfvwAAAAHdElNRQfiBggKKDSs0hKvAAABZ0lEQVRo3r3awa3CMBCEYYsjpfBSFJ1RFIJOOA9HeCS2d70zkxTwS5+SyPG64YYzmu9uAO64eIPAC1dvEICL9hM00X4HLbT/gwbafVBMexSU0h4HhbT9oIh2FJTQjoMC2nmQTPsbvOKlpf0NNmx4KGn3wYYzbjrao2BD09H2gjLafnBE+6cJSmhnQTrtPEimjQSptNEgjTYeJNFmghTabLBMmw8WaVeCJdrV4DLtenCRthJcoq0G07T1YJKWEUzRsoING54RWl4wSMsMhmjZwSktPzihVQSHtKfmvoykT2wK0gvuzofG+loMKfnBCSU7aP14hyh5wSAlK2hdYqQo68EkZTVoXQgvUa4HFylXg9bftRJlPlikzAatmwoUyniQRBkNWre+qJTzIJlyFrRu0Eoo+0ERZS9oHyPIKONB6yjIPOx6MCnnQevA0jySlVD2g9axuvnggJRyH7Qe/jAfb7FQfoLmI0pvbx8aQEN5EOIAAAAASUVORK5CYII=) no-repeat 50% 50%;
				@include background-size(100%);
				content: "";
				display: block;
				height: 42px;
				left: 50%;
				margin: -21px 0 0 -10px;
				position: absolute;
				top: 50%;
				width: 28px;
				z-index: 1;
			}
		}

		.m-play-button:not(.m-play-button--small) &
		{
			@include mq-min($bpDesktop)
			{
				height: 116px;
				margin: -58px 0 0 -58px;
				@include remove-text-hidden();
				@include text-overflow();
				width: 116px;

				&:before
				{
					height: 27px;
					margin: 0 0 0 -7px;
					top: 28px;
					width: 18px;
				}
			}
		}
	}	
	&:hover
	{
		@include deferred
		{
			background: rgba($colorBlack, .75);
			color: $colorWhite;
		}
	}
}