﻿@use "config" as *;
@use "mixins" as *;

// animations
@include deferred
{
	@keyframes button-press
	{
		0%
		{
			@include transform(scale(1));
		}
		20%
		{
			@include transform(scale(.97));
		}
		60%
		{
			@include transform(scale(1));
		}
		80%
		{
			@include transform(scale(.99));
		}
		100%
		{
			@include transform(scale(.99));
		}
	}
	@keyframes buttton-arrow-hover
	{
		0%
		{
			transform: translateX(0);
		}
		49%
		{
			transform: translateX(100%);
		}
		51%
		{
			transform: translateX(-100%);
		}
		100%
		{
			transform: translateX(0);
		}
	}
}

// rows
.form-row, .form-action
{
	@include critical
	{
		font-size: 0;
		line-height: 0;
		margin: 0;
		padding: 0 0 20px 0;
		text-align: left;
		max-width: $maxFormWidth;
	}
	> *
	{
		@include critical
		{
			@include font-size();
			@include line-height();
			@include mq-min($bpDesktop)
			{
				@include font-size(20px);
				@include line-height(28px);
			}
		}
	}
	> label
	{
		@include critical
		{
			display: block;
			padding-bottom: 10px;
		}
	}
	&--close
	{
		@include critical
		{
			padding-top: 0;
		}
	}
	&--tight
	{
		@include critical
		{
			padding-bottom: 0;
		}
	}
}

.form-action
{
	&:not(:first-child)
	{
		@include critical
		{
			padding-bottom: 0;
			padding-top: 20px;
		}
	}
	&--match-lower-page-blocks
	{
		@include critical
		{
			max-width: 100%;
			.form-button
			{
				width: 100%;
				max-width: 100%;
				min-width: 0;
				@include mq-min($bpTablet)
				{
					width: 48.5%;
					max-width: 48.5%;
				}
				@include mq-min($bpDesktop)
				{
					width: 42.5%;
					max-width: 42.5%;
				}
				&:focus
				{
					outline: 4px solid $colorFocusDarkOnLight !important;
					outline-offset: 1px;
				}
			}
		}
	}
}

// required
.form-required
{
	@include critical
	{
		color: $colorError !important;
		font-family: Helvetica, Arial, sans-serif;
		font-weight: bold;
	}
}

// inputs
.form-text, .form-textarea, .form-select
{
	@include critical
	{
		background: none;
		background-color: $colorWhite;
		@include background-clip();
		border: 1px solid $colorDivider;
		@include border-radius(0);
		@include box-shadow(none);
		color: #555;
		display: inline-block;
		font-family: $fontBase;
		@include font-size();
		font-weight: 400;
		@include line-height();
		margin: 0;
		padding: 14px 16px;
		@include placeholder-text($colorBase);
		vertical-align: middle;
		width: 100%;
		max-width: $maxFormWidth;
		@include mq-min($bpDesktop)
		{
			@include font-size(18px);
			@include line-height(26px);
			padding: 18px;
		}
	}
	&:not(:last-child)
	{
		@include critical
		{
			margin-right: 15px;
		}
	}
	&:focus
	{
		@include critical
		{
			border-color: $colorBase;
		}
	}
	&.input-validation-error
	{
	}
	&--no-border
	{
		@include critical
		{
			background-color: transparent;
			border-color: transparent;
		}
	}
}

.form-text
{
	@include critical
	{
		-webkit-appearance: none;
	}
}

.form-textarea
{
	@include critical
	{
		min-height: 100px;
		height: 150px;
		resize: vertical;
		@include mq-min($bpDesktop)
		{
			height: 250px;
		}
	}
}

.form-select
{
	@include critical
	{
		padding: 9px 6px 10px 6px;
	}
}

// input group
.form-input-group
{
	@include critical
	{
		font-size: 0;
		line-height: 0;
		margin: 0 0 -10px 0;
		@include mq-min($bpTablet)
		{
			margin-left: -10px;
		}
	}
	.form-text, .form-textarea, .form-select
	{
		@include critical
		{
			margin: 0 0 10px 0;
			@include mq-min($bpTablet)
			{
				margin-left: 10px;
			}
		}
	}
}

// checks / radios
.form-checks
{
	@include critical
	{
		font-size: 0;
		line-height: 0;
		list-style: none;
		margin: 0;
		padding: 0 0 10px 0;
		text-align: left;
		max-width: $maxFormWidth;
	}
	&__item
	{
		@include critical
		{
			@include box-sizing();
			list-style: none;
			margin: 0;
			padding: 0 0 20px 0;
			@include mq-min($bpDesktop)
			{
				@include font-size(20px);
				@include line-height(28px);
			}
		}
		label
		{
			@include critical
			{
				cursor: pointer;
				display: block;
				@include font-size();
				@include min-height(30px);
				@include line-height();
				margin: 0;
				padding: 4px 0 0 40px;
				position: relative;
				z-index: 1;
				@include mq-min($bpDesktop)
				{
					padding-top: 1px;
				}
			}
			span
			{
				&:before, &:after
				{
					@include critical
					{
						content: "";
						display: block;
						position: absolute;
					}
				}
				&:before
				{
					@include critical
					{
						background: #fbfbfb;
						border: 1px solid $colorGreyLight;
						height: 28px;
						left: 0;
						top: 0;
						width: 28px;
						z-index: 1;
					}
				}
				&:after
				{
					@include critical
					{
						border: 0 solid $colorBody;
						border-bottom-width: 2px;
						border-left-width: 2px;
						height: 5px;
						left: 9px;
						opacity: 0;
						@include rotate(-45deg);
						top: 10px;
						@include transition(opacity .3s);
						width: 10px;
						z-index: 2;
					}
				}
			}
			input
			{
				@include critical
				{
					-moz-appearance: none;
					-webkit-appearance: none;
					appearance: none;
					left: -1px;
					margin: 0;
					opacity: .000001;
					overflow: hidden;
					padding: 0;
					position: absolute;
					top: -1px;
					z-index: 1;
				}
				&[type="radio"]
				{
					& ~ span
					{
						&:before
						{
							@include critical
							{
								@include border-radius(100%);
							}
						}
						&:after
						{
							@include critical
							{
								background: $colorBody;
								border: 0;
								@include border-radius(100%);
								height: 12px;
								@include rotate(0);
								top: 9px;
								width: 12px;
							}
						}
					}
				}
				.js-focus-visible &:focus.focus-visible ~ span {

					@include deferred {
						outline: 4px solid $colorFocus;

						&:before {
							border-color: $colorGrey;
						}
					}
				}
				&:checked
				{
					& ~ span
					{
						&:after
						{
							@include critical
							{
								opacity: 1;
							}
						}
					}
				}
			}
		}
		&--inline
		{
			@include critical
			{
				display: inline-block;
				vertical-align: top;
				width: 100%;
				@include mq-min($bpTablet)
				{
					width: 50%;
					padding-right: 30px;
				}
				@include mq-min($bpDesktop)
				{
					width: 25%;
				}
			}
		}
		&--inline-always
		{
			@include critical
			{
				display: inline-block;
				vertical-align: top;
				
				&:not(:last-child) {
					padding-right: 10px;

					@include mq-min($bpTablet)
					{
						padding-right: 20px;
					}
				}

				label
				{
					padding-top: 2px;
					@include mq-min($bpTablet)
					{
						padding-top: 2px;
					}
					@include mq-min($bpDesktop)
					{
						padding-top: 4px;
						@include font-size(18px);
					}
				}
			}
		}
		&--inline-tablet
		{
			@include critical
			{
				padding-bottom: 0;
				@include mq-min($bpTablet)
				{
					display: inline-block;
					vertical-align: top;
				}
				&:not(:last-child) {
					padding-bottom: 20px;

					@include mq-min($bpTablet)
					{
						padding-right: 20px;
						padding-bottom: 0;
					}
				}

				label
				{
					@include mq-min($bpTablet)
					{
						padding-top: 2px;
					}
					@include mq-min($bpDesktop)
					{
						padding-top: 4px;
						@include font-size(18px);
					}
				}
			}
		}
		&--tight {
			@include critical
			{
				padding-bottom: 0;
			}
		}
	}
	&--sub
	{
		@include critical
		{
			padding-left: 40px;
			padding-top: 20px;
		}
		&__item
		{
			@include critical
			{
				color: $colorGreyDark;
				@include font-size(15px);
				@include line-height(23px);
				@include mq-min($bpDesktop)
				{
					@include font-size(17px);
					@include line-height(25px);
				}
			}
		}
	}
	&--tight
	{
		padding-bottom: 0;
	}
	&--unrestrict
	{
		@include critical
		{
			max-width: 100%;
		}
	}
}

// buttons
.form-button,
.form-button-search
{
	@include critical
	{
		background: none;
		border: 0;
		@include border-radius(0);
		cursor: pointer;
		display: inline-block;
		margin: 0;
		overflow: hidden;
		padding: 0;
		position: relative;
		@include user-select();
		vertical-align: middle;
		white-space: nowrap;
		z-index: 1;
	}
	&:focus
	{
		@include critical
		{
			outline: 4px solid $colorFocus;
		}
	}
}

.form-button
{
	@include critical
	{
		background: $colorBaseDark;
		color: $colorWhite;
		cursor: pointer;
		@include font-size();
		font-weight: 500;
		@include line-height(20px);
		padding: 18px 15px;
		text-align: center;
		text-decoration: none;
		width: 100%;
		max-width: 324px;
		@include mq-min($bpTablet)
		{
			width: auto;
			min-width: 324px;
		}
		@include mq-min($bpDesktop)
		{
			@include font-size(18px);
			@include line-height(22px);
			padding: 22px 15px;
			min-width: 404px;
		}
		.is-safari & {
			@include transition(background .5s);
		}
		&:focus
		{
			outline: 4px solid $colorFocusDarkOnLight;
			outline-offset: 1px;
		}
	}
	&:before
	{
		@include critical
		{
			background: $colorBlackLight;
			@include border-radius(100%);
			content: "";
			display: block;
			height: 0;
			left: 50%;
			opacity: 0;
			position: absolute;
			top: 50%;
			@include transform(translateX(-50%) translateY(-50%));
			@include transition(opacity .5s, width 1s, height 1s, background .5s);
			width: 0;
			z-index: -1;

			.is-safari &:not(.m-global-map__pin__link) {
				display: none;
			}
		}
	}
	&:not(:last-child)
	{
		@include critical
		{
			margin-right: 15px;
		}
	}
	&:focus, &:hover, &:active
	{
		@include critical
		{
			background: $colorBaseDark;
			color: $colorWhite;
		}
		&:before
		{
			@include critical
			{
				height: 500px;
				left: 50%;
				opacity: 1;
				width: 500px;
			}
		}
	}
	.is-safari & {

		&:not(.loading):not(.m-global-map__pin__link):focus,
		&:not(.loading):not(.m-global-map__pin__link):hover,
		&:not(.loading):not(.m-global-map__pin__link):active {

			@include critical {
				background: $colorBlackLight;
			}
		}
	}
	&:active
	{
		@include deferred
		{
			animation: button-press .3s;
		}
	}
	&.loading
	{
		@include deferred
		{
			@include bg("/images/forms/base-loader.gif", no-repeat, 50%  50%);
			cursor: not-allowed;
			@include text-hidden();
		}
		&:before
		{
			@include deferred
			{
				background: none !important;
			}
		}
	}
	&--block
	{
		@include critical
		{
			display: block;
			margin: 0;
			width: 100%;
		}
	}
	&--small
	{
		@include critical
		{
			@include mq-min($bpTablet)
			{
				min-width: 210px;
			}
			@include mq-min($bpTablet)
			{
				min-width: 294px;
			}
		}
	}
	&--secondary
	{
		@include critical
		{
			background: $colorSecondary;
			color: $colorBaseDark;
		}
		&:before
		{
			@include critical
			{
				background: $colorSecondaryDark;
			}
		}
		&:focus, &:hover, &:active
		{
			@include critical
			{
				background: $colorSecondary;
				color: $colorBaseDark;
				outline: 4px solid $colorBaseDark;
			}
		}
		.is-safari & {

			&:not(.loading):focus,
			&:not(.loading):hover,
			&:not(.loading):active {

				@include critical {
					background: $colorSecondaryDark;
				}
			}
		}
		&.loading
		{
			@include deferred
			{
				@include bg("/images/forms/secondary-loader.gif", no-repeat, 50%  50%);
				cursor: not-allowed;
				@include text-hidden();
			}
			&:before
			{
				@include deferred
				{
					background: none !important;
				}
			}
		}
	}
	&--cta
	{
		@include critical
		{
			background: none !important;
			color: inherit;
			@include font-size(20px);
			font-weight: 400;
			@include line-height(28px);
			padding: 0 0 0 38px;
			padding-left: 38px;
			width: auto;
			min-width: 10px;
			max-width: 100%;
			white-space: normal;
			text-align: left;
			min-height: 28px;
			@include mq-min($bpTablet)
			{
				@include font-size(24px);
			}
			@include mq-min($bpDesktop)
			{
				@include font-size(28px);
				@include line-height(40px);
				padding-left: 50px;
				min-height: 40px;
			}
		}
		&__arrow
		{
			@include critical
			{
				position: absolute;
				top: 0;
				left: 0;
				z-index: 1;
				width: 28px;
				height: 28px;
				display: block;
				background: $colorPrimary;
				@include border-radius(100%);
				overflow: hidden;
				@include mq-min($bpDesktop)
				{
					height: 40px;
					width: 40px;
				}
			}
			&__element
			{
				@include critical
				{
					width: 100%;
					height: 100%;
					display: block;
					color: $colorWhite;
				}
			}
		}
		&:hover,
		.cta-hover:hover,
		&--hover,
		&:active,
		&:focus
		{
			@include critical
			{
				color: inherit;
			}
			.form-button--cta__arrow__element
			{
				@include deferred
				{
					animation: buttton-arrow-hover .5s;
				}
			}
		}
		&:before
		{
			@include critical
			{
				display: none !important;
			}
		}
		&--small
		{
			@include critical
			{
				@include font-size(18px);
				@include line-height(30px);
				padding-left: 40px;
				min-height: 30px;
				@include mq-min($bpDesktop)
				{
					@include font-size(22px);
				}
			}
			.form-button--cta__arrow
			{
				@include critical
				{
					height: 30px;
					width: 30px;
				}
			}
		}
		&--light
		{
			@include critical
			{
				color: $colorWhite;
				.form-button--cta__arrow
				{
					background: $colorWhite;
				}
				.form-button--cta__arrow__element
				{
					color: $colorBlack;
				}
			}
			&:focus, &:hover, &:active
			{
				@include critical
				{
					color: $colorWhite;
				}
			}
		}
		&--alt
		{
			.form-button--cta__arrow
			{
				@include critical
				{
					background: $colorBlack;
				}
			}
		}
	}
	&--text-can-stack
	{
		@include critical
		{
			white-space: normal;
			height: 56px;
			position: relative;
			@include mq-min($bpDesktop)
			{
				height: 66px;
			}
		}
		&__inner
		{
			@include critical
			{
				position: absolute;
				padding: 0 15px;
				top: 50%;
				left: 0;
				right: 0;
				@include transform(translateY(-50%));
			}
		}
	}
	&--auto-width {
		@include critical
		{
		min-width: 0;
		width: auto;
		max-width: none;
		padding: 18px 30px;
		@include mq-min($bpDesktop)
		{
			padding: 22px 50px;
		}
		}
	}
}

.form-button--tiny {
	@include critical {
		@include font-size(16px);
		@include line-height(28px);
		padding: 15px;
		min-width: 250px;

		@include mq-min($bpDesktop) {
			@include font-size(20px);
		}
	}
}

.form-button-search
{
	@include critical
	{
		width: 40px;
	}
	> svg
	{
		@include critical
		{
			display: block;
			fill: $colorGrey;
			height: auto;
			max-height: 100%;
			margin: 0 auto;
			width: 28px;
			max-width: 100%;
			@include mq-min($bpDesktop)
			{
				width: 40px;
			}
		}
	}
	&:focus, &:hover
	{
		> svg
		{
			@include critical
			{
				fill: $colorBlack;
			}
		}
	}
	&--white
	{
		> svg
		{
			@include critical
			{
				fill: $colorWhite;
			}
		}
		&:focus, &:hover
		{
			> svg
			{
				@include critical
				{
					fill: $colorGrey;
				}
			}
		}
	}
	&--grey
	{
		&:focus, &:hover
		{
			> svg
			{
				@include critical
				{
					fill: $colorWhite;
				}
			}
		}
	}
}

/* Floating label styles ported from main Dyson website */

@include critical {

	.form__floating-label-container {
		position: relative;
	}

	.form__floating-label {
		position: absolute;
		background-color: $colorWhite;
		top: 50%;
		left: 0;
		transform: translateY(-50%);
		margin: 0 0 0 .5rem;
		padding: 0 .5rem;
		pointer-events: none;
		transition: transform .1s ease-in;
		line-height: 1.44;
		font-size: 1rem;
	}

	.form__floating-label-input:valid + .form__floating-label,
	.form__floating-label-input:focus + .form__floating-label {
		transform: translateY(-185%);
	}

}

/* Mega select styles ported from main Dyson website */
@include critical {

	.form__mega-select {
		position: relative;
		box-sizing: border-box;
		-moz-appearance: none;
		-webkit-appearance: none;
		margin: 0;
		height: 3.5rem;
		border: 1px solid #919191;
		border-radius: 0;
		padding: 1rem;
		width: 100%;
		background-color: $colorWhite;
		color: #555;
		font-family: $fontBase;
		font-size: 1rem;
		outline: 0;
		transition: box-shadow .15s ease-in;
		background-clip: padding-box;
		cursor: pointer;
		padding-right: 2.25rem;
		padding-top: .875rem;
		line-height: 110%;
		text-indent: .01px;
	}

	.form__mega-select:focus {
		outline: .125rem solid $colorBaseDark;
	}

	.form__mega-select::-ms-expand {
		display: none;
	}

	.form__mega-select-container {
		position: relative;
	}

	.form__mega-select-container:after {
		content: '';
		position: absolute;
		top: 50%;
		margin-top: -6px;
		right: 1rem;
		background-color: $colorWhite;
		background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMS42NiAxOC42NiI+ICA8cG9seWxpbmUgcG9pbnRzPSIyOC44MyAyLjgzIDE1LjgzIDE1LjgzIDIuODMgMi44MyIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjMDAwIiBzdHJva2UtbGluZWNhcD0ic3F1YXJlIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIHN0cm9rZS13aWR0aD0iNCIvPjwvc3ZnPg==);
		background-repeat: no-repeat;
		background-size: 20px 14px;
		height: 14px;
		width: 20px;
	}
}

.form-button--share {

	@include critical {
		position: relative;
		min-width: 0;
		padding: 6px 20px 4px 45px;
		width: auto;

		@include mq-min($bpDesktop) {
			padding: 10px 32px 8px 60px;
		}
	}
}

.form-button--share__icon {

	@include critical {
		width: 18px;
		height: 18px;
		display: block;
		position: absolute;
		top: 50%;
		left: 16px;
		margin: -9px 0 0 0;

		@include mq-min($bpDesktop) {
			width: 24px;
			height: 24px;
			left: 24px;
			margin: -12px 0 0 0;
		}
	}
}

.form-button--share__icon--ios {

	@include critical {
		left: 18px;
		margin: -10px 0 0 0;

		@include mq-min($bpDesktop) {
			margin: -13px 0 0 0;
			left: 24px;
		}
	}
}