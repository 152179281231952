﻿@use "../config" as *;
@use "../mixins" as *;

.l-fallback-nav
{
	@include deferred
	{
		background: $colorBlack;
		padding: 56px 0;
		@include mq-min($bpTablet)
		{
			padding: 60px 0;
		}
		@include mq-min($bpDesktop)
		{
			display: none !important;
		}
	}
	&__inner
	{
		@include deferred
		{
			text-align: left;
		}
	}
	&__menu
	{
		&__item
		{
			&:not(:first-child)
			{
				@include deferred
				{
					border-top: 1px solid $colorGrey;
				}
			}
		}
		&__link
		{
			@include deferred
			{
				color: $colorWhite;
				display: block;
				@include font-size(18px);
				@include line-height(26px);
				padding: 24px 0;
			}
		}		
	}
	&__sub-menu
	{
		@include deferred
		{
			border-top: 1px solid $colorGrey;
		}
		&__link
		{
			@include deferred
			{
				border-bottom: 1px solid $colorNavDivider;
				color: $colorWhite;
				display: block;
				@include font-size();
				font-weight: 300;
				@include line-height();
				padding: 24px;
			}
		}
	}
	.js &
	{
		@include deferred
		{
			display: none !important;
		}
	}
}