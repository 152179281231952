﻿@use "config" as *;
@use "mixins" as *;
@use "fonts" as *;

// reset based on: http://meyerweb.com/eric/tools/css/reset/
html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p,
blockquote, pre, a, abbr, acronym, address, cite, code, del, dfn, em,
img, ins, kbd, q, s, samp, small, strong, sub, sup, var, b, u, i,
center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption,
tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, figcaption,
figure, footer, header, hgroup, main, menu, nav, section, summary, time, mark, audio, video,
input, select, textarea, button, picture, img
{
	@include critical
	{
		border: 0;
		font: inherit;
		font-size: 100%;
		margin: 0;
		outline: 0;
		padding: 0;
		vertical-align: baseline;
		@include box-sizing();
	}	
}

main, article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section, audio, video, canvas
{
	@include critical
	{
		display: block;
	}	
}

body
{
	@include critical
	{
		line-height: 1;
	}	
}

blockquote, q
{
	@include critical
	{
		quotes: none;
	}
	&:before, &:after
	{
		@include critical
		{
			content: '';
			content: none;
		}		
	}
}

:focus
{
	@include critical
	{
		outline: none;
	}	
}

table
{
	@include critical
	{
		border-collapse: collapse;
		border-spacing: 0;
		width: 100%;
	}
}

abbr[title]
{
	@include critical
	{
		border-bottom: 0;
		cursor: help;
		text-decoration: none;
	}	
}

address
{
	@include critical
	{
		display: block;
		font-style: normal;
		margin: 0;
		padding: 0;
	}	
}

small
{
	@include critical
	{
		color: $colorGrey;
		font-size: 85%;
	}
}

sub, sup
{
	@include critical
	{
		font-size: 85%;
		line-height: 0;
		margin: 0;
		padding: 0;
		position: relative;
		vertical-align: baseline;
	}	
}

sup
{
	@include critical
	{
		top: -0.5em;
	}	
}

sub
{
	@include critical
	{
		bottom: -0.25em;
	}	
}

figure
{
	@include critical
	{
		width: 100%;
		max-width: 100%;
	}	
}

picture, img
{
	@include critical
	{
		border: 0;
		display: block;
		height: auto;
		margin: 0 auto;
		padding: 0;
		width: auto;
		max-width: 100%;
	}	
}

input[type="search"]
{
	@include critical
	{
		-webkit-appearance: none;
	}
}

button, html input[type="button"], html input[type="reset"], html input[type="submit"]
{
	@include critical
	{
		-webkit-appearance: button;
	}	
}

button::-moz-focus-inner, input::-moz-focus-inner
{
	@include critical
	{
		border: 0;
		margin: 0;
		padding: 0;
	}	
}

::-ms-clear 
{
	@include critical
	{
		display: none;
	}
}

::-webkit-search-decoration,
::-webkit-search-cancel-button,
::-webkit-search-results-button,
::-webkit-search-results-decoration 
{ 
  @include critical
	{
		display: none;
	}
}

// pre
pre
{
	@include deferred
	{
		background: $colorBlack;
		color: $colorWhite;
		font-family: monospace;
		font-size: 12px;
		line-height: 24px;
		margin: 0 0 20px 0;
		padding: 10px 15px;
		overflow-x: auto;
		tab-size: 4;
	}
}

// links
a
{
	@include critical
	{
		color: $colorLink;
		display: inline-block;
		text-decoration: none;
	}
	&:focus, &:hover
	{
		@include critical
		{
			color: $colorLink;
			text-decoration: none;
		}		
	}
	&:hover
	{
		@include critical
		{
			color: $colorLinkHover;
		}		
	}

	@include deferred {

		@include focus-hide() {
			outline: none;
		}

		.js-focus-visible &:focus.focus-visible {
			outline: 4px solid $colorFocus;
		}
	}
}

// styles
b, strong
{
	@include critical
	{
		font-weight: 700;
	}
}

i, em
{
	@include critical
	{
		color: $colorBlack;
		font-style: italic;
	}
}

// headers
h1, h2, h3, h4, h5, h6
{
	@include critical
	{
		font-weight: 400;
		margin: 0;
		padding: 0 0 15px 0;
	}	
}

h1, .h1
{
	@include critical
	{
		@include font-size(40px);
		font-weight: 500;
		@include line-height(48px);
		@include mq-min($bpTablet)
		{
			@include font-size(48px);
			@include line-height(56px);
		}
		@include mq-min($bpDesktop)
		{
			@include font-size(64px);
			@include line-height(72px);
		}
	}
}

h2, .h2
{
	@include critical
	{
		@include font-size(28px);
		font-weight: 500;
		@include line-height(36px);
		@include mq-min($bpTablet)
		{
			@include font-size(32px);
			@include line-height(40px);
		}
		@include mq-min($bpDesktop)
		{
			@include font-size(40px);
			@include line-height(48px);
		}
	}
}

h3, .h3
{
	@include critical
	{
		@include font-size(28px);
		@include line-height(36px);
		@include mq-min($bpTablet)
		{
			@include font-size(32px);
			@include line-height(40px);
		}
		@include mq-min($bpDesktop)
		{
			@include font-size(40px);
			@include line-height(48px);
		}
	}
}

h4, .h4
{
	@include critical
	{
		@include font-size(24px);
		font-weight: 500;
		@include line-height(32px);
		@include mq-min($bpTablet)
		{
			@include font-size(28px);
			@include line-height(36px);
		}
	}
}

h5, .h5
{
	@include critical
	{
		@include font-size();
		@include line-height();
		text-transform: uppercase;
		@include mq-min($bpTablet)
		{
			@include font-size(20px);
			@include line-height(28px);
			text-transform: none;
		}
	}
}

h6, .h6
{
	@include critical
	{
		@include font-size();
		font-weight: 500;
		@include line-height();
	}
}

// content
p
{
	@include critical
	{
		margin: 0;
		padding: 0 0 15px 0;
	}	
}

ul, ol, li
{
	@include critical
	{
		list-style: none;
		margin: 0;
		padding: 0;
	}
}

// other

hr
{
	@include critical
	{
		background: none;
		border: 0;
		height: 26px;
		margin: 0;
		overflow: hidden;
		padding: 0;
		position: relative;
		visibility: hidden;
		z-index: 1;
	}
	&:before
	{
		@include deferred
		{
			border-top: 1px solid $colorDivider;
			content: "";
			display: block;
			left: 0;
			position: absolute;
			right: 0;
			top: 5px;
			visibility: visible;
			z-index: 1;
		}			
	}
}