﻿@use "../config" as *;
@use "../mixins" as *;

.c-social__title {

  @include critical {
    color: $colorWhite;
  }
}

.c-social__list {

  @include critical {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: flex-start;
  }
}

.c-social__item {

  @include critical {

    &:not(:first-child) {
      margin: 0 0 0 15px;
    }
  }
}

.c-social__item,
.c-social__icon,
.c-social__link {

  @include critical {
    width: 28px;
    height: 28px;
    display: block;
  }
}

.c-social__link {

  @include deferred {
    color: $colorBorderAlt;

    &:hover,
    &:focus {
      opacity: 0.75;
      color: $colorBorderAlt;
    }
  }
}
